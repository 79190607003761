import {KpiStatusEnum, KpiStatusPrognoseEnum, KPITypeEnum, MijlpaalEnum, MijlpaalPrognoseEnum, OnderwerpEnum, PeriodEnum, PrognoseSchaalEnum, StatusEnum, WaardeTypeEnum, WachttijdApiTypeEnum} from './enums&types';
import {CabPeriod, CabReportItem, REPORTED_VALUE_UNKNOWN} from './index';

export function PeriodEnum_name(e: PeriodEnum): string {
    switch (e) {
        case PeriodEnum.Q:
            return 'kwartaal';
        case PeriodEnum.H:
            return 'half jaar';
    }
}

export function PeriodEnum_char(e: PeriodEnum): string {
    switch (e) {
        case PeriodEnum.Q:
            return 'Q';
        case PeriodEnum.H:
            return 'H';
    }
}

export function OnderwerpEnum_name(e: OnderwerpEnum | undefined): string {
    switch (e) {
        //case OnderwerpEnum.mijlpaal:
        case OnderwerpEnum.proces:
            return 'Proces';
        case OnderwerpEnum.adherentie:
            return 'Adherentie';
        case OnderwerpEnum.financien:
            return 'Financiën';
        case OnderwerpEnum.personeel:
            return 'Personeel';
        case OnderwerpEnum.productiviteit:
            return 'Productiviteit';
        case OnderwerpEnum.quadrupleAim:
            return 'Quadruple Aim';
        case OnderwerpEnum.toegankelijkheid:
            return 'Toegankelijkheid';
        case OnderwerpEnum.volumes:
            return 'Volumes';
        default:
            return '???';
    }
}

const OnderwerpEnum_sortering: OnderwerpEnum[]         = [
    OnderwerpEnum.proces,
    OnderwerpEnum.financien,
    OnderwerpEnum.personeel,
    OnderwerpEnum.volumes,
    OnderwerpEnum.toegankelijkheid,
    OnderwerpEnum.productiviteit,
    OnderwerpEnum.adherentie,
    OnderwerpEnum.quadrupleAim,
];
const onderwerpSorteerPrio: Map<OnderwerpEnum, number> = new Map(OnderwerpEnum_sortering.map((enumValue, index) => [enumValue, index]));

export function OnderwerpEnum_compare(a: OnderwerpEnum, b: OnderwerpEnum): number {
    return onderwerpSorteerPrio.get(a)! - onderwerpSorteerPrio.get(b)!;
}

export function WaardeTypeEnum_name(e: WaardeTypeEnum): string {
    switch (e) {
        case WaardeTypeEnum.getal:
            return 'geheel getal';
        case WaardeTypeEnum.percentage:
            return '% percentage';
        case WaardeTypeEnum.bedrag:
            return '€ bedrag';
        case WaardeTypeEnum.decimaal:
            return 'decimaal getal';
    }
}

export function VoorspellingsSchaalEnum_name(e: PrognoseSchaalEnum | undefined): string {
    switch (e) {
        case PrognoseSchaalEnum.none:
            return 'geen prognose';
        case PrognoseSchaalEnum.once:
            return 'één prognose';
        case PrognoseSchaalEnum.all:
            return 'traject prognose';
        default:
            return '???';
    }
}

export function KPITypeEnum_name(e: KPITypeEnum) {
    switch (e) {
        case KPITypeEnum.input:
            return 'input KPI';
        case KPITypeEnum.output:
            return 'output KPI';
        case KPITypeEnum.onderliggend:
            return 'onderliggende KPI';
    }
}

export function MijlpaalEnum_name(e: MijlpaalEnum | undefined): string {
    switch (e) {
        case MijlpaalEnum.gehaald:
            return 'mijlpaal gehaald';
        case MijlpaalEnum.gemist:
            return 'mijlpaal gemist';
        case MijlpaalEnum.onbekend:
            return 'mijlpaal status onbekend';
        case MijlpaalEnum.gestart:
            return 'mijlpaal gestart';
    }
    return GEEN_WAARDE_REPRESENTATION;
}

export function KpiStatusEnum_name(e: KpiStatusEnum | undefined): string {
    switch (e) {
        case KpiStatusEnum.gehaald:
            return 'KPI gehaald';
        case KpiStatusEnum.gemist:
            return 'KPI gemist';
        case KpiStatusEnum.onbekend:
            return 'KPI status onbekend';
    }
    return GEEN_WAARDE_REPRESENTATION;
}

export function MijlpaalPrognoseEnum_name(e: MijlpaalPrognoseEnum | undefined): string {
    switch (e) {
        case MijlpaalPrognoseEnum.wordtGehaald:
            return 'mijlpaal zal worden gehaald';
        case MijlpaalPrognoseEnum.kansOpMissen:
            return 'kans op missen mijlpaal aanwezig';
        case MijlpaalPrognoseEnum.wordtGemist:
            return 'mijlpaal zal worden gemist';
    }
    return GEEN_WAARDE_REPRESENTATION;
}

export function KpiStatusPrognoseEnum_name(e: KpiStatusPrognoseEnum | undefined): string {
    switch (e) {
        case KpiStatusPrognoseEnum.wordtGehaald:
            return 'KPI zal worden gehaald';
        case KpiStatusPrognoseEnum.kansOpMissen:
            return 'kans op missen KPI aanwezig';
        case KpiStatusPrognoseEnum.wordtGemist:
            return 'KPI zal worden gemist';
    }
    return GEEN_WAARDE_REPRESENTATION;
}

export function StatusEnum_name(e: StatusEnum | undefined): string {
    switch (e) {
        case StatusEnum.future:
            return 'toekomst';
        case StatusEnum.open:
            return 'open';
        case StatusEnum.overdue:
            return 'overtijd';
        case StatusEnum.submitted:
            return 'ingediend';
        case StatusEnum.accepted:
            return 'geaccepteerd';
        case StatusEnum.rejected:
            return 'afgewezen';
    }
    return GEEN_WAARDE_REPRESENTATION;
}

export function WachttijdApiTypeEnum_name(e: WachttijdApiTypeEnum | undefined | 'CSV'): string {
    switch (e) {
        case WachttijdApiTypeEnum.MSZ:
            return 'MSZ';
        case WachttijdApiTypeEnum.GGZ:
            return 'GGZ';
        case 'CSV':
            return 'CSV';
    }
    return GEEN_WAARDE_REPRESENTATION;
}

export function StatusEnum_color(e: StatusEnum | undefined) {
    return `${StatusEnum_baseColor(e)}-${e === StatusEnum.future ? 300 : 200}`;
}

export function StatusEnum_heavyColor(e: StatusEnum | undefined) {
    return `${StatusEnum_baseColor(e)}-${e === StatusEnum.future ? 400 : 300}`;
}

// see tailwind.config.js, if you change this, make equivalent changes there!
const OVERDUE_COLOR: string   = 'orange';
const OPEN_COLOR: string      = 'blue';
const FUTURE_COLOR: string    = 'slate';
const SUBMITTED_COLOR: string = 'lime';
const ACCEPTED_COLOR: string  = 'teal';
const UNDEFINED_COLOR: string = 'lime';
const REJECTED_COLOR: string  = 'red';

export function StatusEnum_baseColor(e: StatusEnum | undefined) {
    switch (e) {
        case StatusEnum.overdue:
            return OVERDUE_COLOR;
        case StatusEnum.open:
            return OPEN_COLOR;
        case StatusEnum.future:
            return FUTURE_COLOR;
        case StatusEnum.submitted:
            return SUBMITTED_COLOR;
        case StatusEnum.accepted:
            return ACCEPTED_COLOR;
        case StatusEnum.rejected:
            return REJECTED_COLOR;
    }
    return UNDEFINED_COLOR;
}

export function renderRI(ri: CabReportItem): string {
    return `RI[${ri.uuid} ${renderCabPeriod(ri.period[0])} ## ${ri.kpi[0].name} ## ${ri.organization[0].name} ## ${ri.theme[0].name}`;
}

export function renderCabPeriod(p: CabPeriod): string {
    return `${p.year}-${PeriodEnum_char(p.unit)}${p.num + 1}`;
}

export function renderSet(s: Set<any>) {
    return '[' + [...s].map(e => JSON.stringify(e)).join(',') + ']';
}

const GEEN_WAARDE_REPRESENTATION = '';

export function formatWaarde(waarde: number | undefined, waardeType: WaardeTypeEnum | undefined) {
    if (waarde == undefined) {
        // console.log(`FORMAT: undefined => '${GEEN_WAARDE_REPRESENTATION}'`);
        return GEEN_WAARDE_REPRESENTATION;
    }
    if (waarde === REPORTED_VALUE_UNKNOWN) {
        return 'onbekend';
    }
    const neg = waarde < 0;
    if (neg) {
        waarde = -waarde;
    }
    const formattedNumber: string = waarde.toLocaleString('en-US', {
        minimumFractionDigits: waardeType == WaardeTypeEnum.decimaal ? 2 : waardeType == WaardeTypeEnum.percentage ? 1 : 0,
        maximumFractionDigits: waardeType == WaardeTypeEnum.decimaal ? 2 : waardeType == WaardeTypeEnum.percentage ? 1 : 0,
        useGrouping          : true,
    }).replace(/,/g, 'X')
        .replace(/[.]/g, ',')
        .replace(/X/g, '.');

    const sign: string = neg ? '- ' : '';
    const pre: string  = waardeType === WaardeTypeEnum.bedrag ? '€ ' : '';
    const post: string = waardeType === WaardeTypeEnum.percentage ? ' %' : '';
    // noinspection UnnecessaryLocalVariableJS
    const s: string    = sign + pre + formattedNumber + post;
    // console.log(`FORMAT: ${waarde} => '${s}'`);
    return s;
}

export function parseWaarde(value: string | undefined): number | undefined {
    if (value === undefined) {
        // console.log(`PARSE: undefined => undefined`);
        return undefined;
    }
    if (value === GEEN_WAARDE_REPRESENTATION) {
        // console.log(`PARSE: '${value}' => undefined`);
        return undefined;
    }
    if (value === 'onbekend') {
        return REPORTED_VALUE_UNKNOWN;
    }
    const neg: boolean            = (value.replace(/[^-]/g, '').length % 2) == 1;
    const v: string               = value
        .replace(/[^0-9,.]/g, '')
        .replace(/^$/, '0')
        .replace(/^0([0-9])/, '$1')
        .replace(/[.]/g, '')
        .replace(/,/, '.')
        .replace(/,/g, '')
    ;
    // noinspection UnnecessaryLocalVariableJS
    const num: undefined | number = v === '' ? undefined : neg ? -v : +v;
    // console.log(`PARSE: '${value}' => '${v}' => ${num}`);
    return num;
}
