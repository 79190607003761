<script setup lang="ts">
import {nextTick, type PropType, ref, toRaw, watch} from 'vue';
import {ORGANIZATION_CHANGE_EMIT, ORGANIZATION_REMOVE_EMIT} from '@/base/cabConstants';
import {
    CabObj,
    type CabOrganization,
    CabTheme,
    enum_values,
    uuidOf,
    WachttijdApiTypeEnum,
    WachttijdApiTypeEnum_name,
} from 'common';
import DeleteButton from '@/views/icons/DeleteIcon.vue';
import {ElButton, ElCascader, ElDialog, ElOption, ElSelect} from 'element-plus';

const emits = defineEmits([ORGANIZATION_REMOVE_EMIT, ORGANIZATION_CHANGE_EMIT]);
const props = defineProps({
    organization: {
        type    : Object as PropType<CabOrganization>,
        required: true,
    },
    allThemes   : {
        type    : Object as PropType<CabTheme[]>,
        required: true,
    },
});

const organization     = ref<CabOrganization>(props.organization);
const themeCascaderRef = ref();
const allThemes        = ref();
const selectedThemes   = ref();
const nameField        = ref<HTMLInputElement | null>(null);
const kvkField         = ref<HTMLInputElement | null>(null);
const apiField         = ref<HTMLInputElement | null>(null);
const apiDialogVisible = ref<boolean>(false);
const nameInfo         = {
    field    : nameField,
    isEditing: ref<boolean>(false),
};
const kvkInfo          = {
    field    : kvkField,
    isEditing: ref<boolean>(false),
};

watch(() => props.allThemes, () => {
        allThemes.value = makeCascaderOptions(props.allThemes);
    },
);

watch(() => props.organization, () => {
        selectedThemes.value = props.organization.themes.map((t) => [uuidOf(t)]);
    },
    {immediate: true},
);

function makeCascaderOptions<T extends CabObj>(list: T[]): any {
    return list.map((o) => ({
        label: (o as any).name,
        value: uuidOf(o),
    }));
}

//==========================================
function startEditing(info: any) {
    info.isEditing.value = true;
    nextTick(() => nextTick(() => info.field.value.focus()));
}

function stopEditing(info: any) {
    info.isEditing.value = false;
    info.field.value.blur();
}

function clearApi() {
    organization.value.wachttijdApiType = undefined;
    apiField.value?.blur();
}

function onDelete() {
    emits(ORGANIZATION_REMOVE_EMIT, organization.value);
}

function onChangeThemes() {
    const rawSelected         = toRaw(selectedThemes.value).map((l: any) => toRaw(l)[0]);
    organization.value.themes = props.allThemes?.filter((theme) =>
        rawSelected.includes(uuidOf(theme)),
    );
    onChange();
}

function onChange() {
    emits(ORGANIZATION_CHANGE_EMIT, organization.value);
}

function dynInputClass(info: any) {
    return info.isEditing.value
           ? `bg-white border-gray-300 rounded-md shadow-xl`
           : `bg-yellow-100`;
}
</script>

<template>
    <div
        class="flex flex-row items-center w-full font-medium m-0.5 p-1 bg-yellow-100 text-gray-900"
    >
        <div class="w-96 px-3 py-1" title="instelling naam">
            <input
                class="mt-1 mr-1 px-2 py-1 border-1 w-full"
                :class="dynInputClass(nameInfo)"
                type="text"
                ref="nameField"
                v-model="organization.name"
                @input="onChange"
                @click="startEditing(nameInfo)"
                @focus="startEditing(nameInfo)"
                @keyup.enter="stopEditing(nameInfo)"
                @blur="stopEditing(nameInfo)"
                placeholder="name"
            />
        </div>

        <div class="text-xs text-gray-400 ml-4">
            themas:
        </div>

        <div class="p-1 flex-1" title="thema's voor deze instelling">
            <el-cascader
                class="w-full"
                ref="themeCascaderRef"
                v-model="selectedThemes"
                :options="allThemes"
                :props="{ multiple: true }"
                clearable
                placeholder="selecteer thema's"
                @change="onChangeThemes"
            />
        </div>

        <el-button type="primary"
            @click="apiDialogVisible = true"
            class="w-24 mx-3"
            :class="organization.kvkNumber||organization.wachttijdApiType ? 'font-extrabold' : 'font-thin'"
        >
            API
        </el-button>

        <DeleteButton @click="onDelete()"/>
    </div>

    <el-dialog
        v-model="apiDialogVisible"
        :close-on-press-escape="true"
        :close-on-click-modal="true"
        :title="`Wachttijden API gerelateerde informatie voor ${organization.name}`"
    >
        <div class="flex flex-col min-w-60 min-h-40 bg-gray-300 p-3">

            <div class="flex flex-row items-center w-full px-3 py-3">
                <div class="text-gray-500 mx-4 w-32 text-right">
                    kvk nummer:
                </div>
                <input
                    class="mt-1 mr-1 px-2 py-1 border-1"
                    :class="dynInputClass(kvkInfo)"
                    type="text"
                    ref="kvkField"
                    v-model="organization.kvkNumber"
                    @input="onChange"
                    @click="startEditing(kvkInfo)"
                    @focus="startEditing(kvkInfo)"
                    @keyup.enter="stopEditing(kvkInfo)"
                    @blur="stopEditing(kvkInfo)"
                    placeholder="kvk nummer"
                />
            </div>

            <div class="flex flex-row items-center w-full px-3 py-3">
                <div class="text-gray-500 mx-4 w-32 text-right">
                    API type:
                </div>
                <el-select
                    v-model="organization.wachttijdApiType"
                    placeholder="API"
                    @change="onChange"
                    class="w-44"
                    ref="apiField"
                    clearable
                    @keyup.delete="clearApi()"
                >
                    <el-option
                        v-for="item in enum_values(WachttijdApiTypeEnum)"
                        :key="item"
                        :label="WachttijdApiTypeEnum_name(item)"
                        :value="item"
                    />
                </el-select>
            </div>
        </div>
    </el-dialog>

</template>
