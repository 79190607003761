import {getBackendUrl} from './frontendConfig.js';
import {CabKPI, CabOrganization, CabPeriod, CabReportItem, CabTheme, CabUser, clrInSync, JSONparse, JSONstringify, TRACE_CALL_BACKEND} from 'common';
import {plainToInstance} from 'class-transformer';
import {debounce} from '@/base/debouncer.js';
import {ReportGroup} from '@/base/utils.js';

const TRACE: boolean        = TRACE_CALL_BACKEND;
const TRACE_STACKS: boolean = false;

const METHOD_GET: string    = 'GET';
const METHOD_POST: string   = 'POST';
const METHOD_DELETE: string = 'DELETE';
const METHOD_PATCH: string  = 'PATCH';

///////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
export async function syncUsers() {
    return await backend(METHOD_GET, `/api/admin/user/sync`, undefined);
}

export async function getMessages(): Promise<string[]> {
    return await backend(METHOD_GET, `/api/admin/messages`, undefined);
}

export async function clearMessages() {
    await backend(METHOD_POST, `/api/admin/messages/clear`, undefined);
}

export async function isOpenForReporters(): Promise<boolean> {
    return await backend(METHOD_GET, `/api/admin/open-for-reporters`, undefined);
}

export async function setOpenForReporters(open: boolean) {
    return await backend(METHOD_POST, `/api/admin/open-for-reporters`, {open});
}

export async function devEraseDatabase() {
    return await backend(METHOD_GET, `/api/dev/erase-database`, undefined);
}

// noinspection JSUnusedGlobalSymbols
export async function devCurrentDate(d: Date) {
    return await backend(METHOD_POST, `/api/dev/current-date`, {date: d});
}

export async function todo(): Promise<string> {
    return await backend(METHOD_GET, `/api/dev/todo`, undefined);
}

export async function expressieDoc(): Promise<string> {
    return await backend(METHOD_GET, `/api/dev/expressiedoc`, undefined);
}

export async function backup(): Promise<Response> {
    return await backendRaw(METHOD_GET, `/api/dev/backup`);
}

export async function restore(body: any): Promise<Response> {
    return await backendRaw(METHOD_POST, `/api/dev/restore`, body);
}

export async function verifyDatabase(): Promise<any> {
    return await backend(METHOD_GET, `/api/dev/verifyDatabase`, undefined);
}

export async function expressionDump(): Promise<any> {
    return await backendRaw(METHOD_GET, `/api/dev/expressiondump`);
}

export async function riCsvExport(filterForCurrentUser: boolean): Promise<Response> {
    const params = {filterForCurrentUser: filterForCurrentUser.toString()};
    return await backendRaw(METHOD_GET, `/api/riCsvExport`, undefined, undefined, params);
}

export async function tnComplianceCsvExport(period: CabPeriod): Promise<Response> {
    return await backendRaw(METHOD_POST, `/api/dev/tnComplianceCsvExport`, JSONstringify({period}), 'application/json');
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
export async function listKpis(selector?: {}): Promise<CabKPI[]> {
    const rawList = await backend(METHOD_POST, `/api/admin/kpi`, selector || {});
    return plainToInstance(CabKPI, rawList as CabKPI[]);
}

export async function deleteKpi(kpi: CabKPI) {
    clrInSync(kpi);
    await backend(METHOD_DELETE, `/api/admin/kpi/delete`, kpi);
}

export async function readKpi(kpi: CabKPI) {
    clrInSync(kpi);
    const newKPI = await backend(METHOD_POST, `/api/admin/kpi/read`, kpi);
    return plainToInstance(CabKPI, newKPI as CabKPI);
}

export async function updateKpi(kpi: CabKPI) {
    clrInSync(kpi);
    const newKpi: CabKPI | undefined = await debounce(kpi.uuid,
        async () => {
            return await backend(METHOD_PATCH, `/api/admin/kpi/change`, kpi);
        },
    );
    return newKpi ? plainToInstance(CabKPI, newKpi) : undefined;
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
export async function listUsers(selector?: {}) {
    const rawList = await backend(METHOD_POST, `/api/admin/user`, selector || {});
    return plainToInstance(CabUser, rawList as CabUser[]);
}

export async function deleteUser(user: CabUser) {
    clrInSync(user);
    await backend(METHOD_DELETE, `/api/admin/user/delete`, user);
}

export async function updateUser(user: CabUser) {
    clrInSync(user);
    await debounce(
        user.uuid, async () => await backend(METHOD_PATCH, `/api/admin/user/change`, user),
    );
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
export async function listOrganizations(selector?: {}): Promise<CabOrganization[]> {
    const rawList = await backend(METHOD_POST, `/api/admin/organization`, selector || {});
    return plainToInstance(CabOrganization, rawList as CabOrganization[]);
}

export async function deleteOrganization(organization: CabOrganization) {
    clrInSync(organization);
    await backend(METHOD_DELETE, `/api/admin/organization/delete`, organization);
}

export async function updateOrganization(organization: CabOrganization) {
    clrInSync(organization);
    await debounce(organization.uuid,
        async () => await backend(METHOD_PATCH, `/api/admin/organization/change`, organization),
    );
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
export async function listThemes(selector?: {}) {
    const rawList = await backend(METHOD_POST, `/api/admin/theme`, selector || {});
    return plainToInstance(CabTheme, rawList as CabTheme[]);
}

export async function deleteTheme(theme: CabTheme) {
    clrInSync(theme);
    await backend(METHOD_DELETE, `/api/admin/theme/delete`, theme);
}

export async function updateTheme(theme: CabTheme) {
    clrInSync(theme);
    await debounce(theme.uuid,
        async () => await backend(METHOD_PATCH, `/api/admin/theme/change`, theme),
    );
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
export async function listAdminItems(selector?: {}): Promise<CabReportItem[]> {
    return plainToInstance(CabReportItem, await backend(METHOD_POST, `/api/admin/reportitem`, selector || {}));
}

export async function updateAdminItem(ri: CabReportItem) {
    clrInSync(ri);
    const newItem: CabReportItem | undefined = await debounce(ri.uuid,
        async () => await backend(METHOD_PATCH, `/api/admin/reportitem/change`, ri),
    );
    return newItem ? plainToInstance(CabReportItem, newItem) : undefined;
}

export async function readAdminItem(ri: CabReportItem): Promise<CabReportItem> {
    clrInSync(ri);
    const answer: CabReportItem = await backend(METHOD_POST, `/api/admin/reportitem/read`, ri);
    return plainToInstance(CabReportItem, answer);
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
// noinspection JSUnusedGlobalSymbols
export async function listMyOrganizations(selector?: {}) {
    return plainToInstance(CabOrganization, await backend(METHOD_POST, `/api/report/organizations`, selector || {}));
}

export async function listReporterReportItems(group: ReportGroup): Promise<CabReportItem[]> {
    let path: string;
    switch (group) {
        case ReportGroup.verleden:
            path = `/api/report/reportitem/verleden`;
            break;
        case ReportGroup.heden:
            path = `/api/report/reportitem/heden`;
            break;
        case ReportGroup.toekomst:
            path = `/api/report/reportitem/toekomst`;
    }
    return plainToInstance(CabReportItem, await backend(METHOD_POST, path));
}

export async function updateReportItem(item: CabReportItem) {
    clrInSync(item);
    const newItem: CabReportItem | undefined = await debounce(item.uuid,
        async () => await backend(METHOD_PATCH, `/api/report/reportitem/change`, item),
    );
    return newItem ? plainToInstance(CabReportItem, newItem) : undefined;
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
export async function getMe() {
    return await backend(METHOD_GET, `/api/me`, undefined);
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////
async function backend(method: string, path: string, body?: any) {  //TODO: body non-optional?
    const response: Response = await backendRaw(method, path, body ? JSONstringify(body) : undefined, body ? 'application/json' : undefined);
    const txt: string        = await response.text();
    let parsed;
    try {
        parsed = JSONparse(txt);
    } catch (error) {
        throw new BackendError(`Failed to parse JSON response`, {rawResponse: txt});
    }
    if (!response.ok) {
        throw new BackendError(`backend call did not succeed: ${method} ${path} (${response.status})`, parsed);
    }
    return parsed;
}

async function backendRaw(method: string, path: string, body?: any, contentType?: string, params?: Record<string, string>) {
    if (!path.startsWith(`/`)) {
        throw new Error(`backend calls should start with '/': ${method} ${path}`);
    }
    const headers: [string, string][] = [];
    const init: RequestInit           = {
        method : method,
        headers: headers,
    };
    if (body) {
        init.body = body;
    }
    if (contentType) {
        headers.push(['Content-Type', contentType]);
    }
    const url = getBackendUrl();
    if (params) {
        const queryString = new URLSearchParams(params).toString();
        path += `?${queryString}`;
    }
    if (TRACE) {
        if (TRACE_STACKS) {
            console.trace(`BACKEND CALL: >>>>> ${method.padEnd(5)} ${path}`);
        } else {
            console.info(`BACKEND CALL: >>>>> ${method.padEnd(5)} ${path}`);
        }
    }
    const response = await fetch(`${url}${path}`, init);
    if (TRACE) {
        console.info(`BACKEND CALL: ${method.padEnd(5)} <<<<< ${path}\n\n`);
    }
    return response;
}

export class BackendError extends Error {
    constructor(message: string, public details: any) {
        super(message);
        this.name = 'BackendError';
    }
}
