<script setup lang="ts">
import {ElButton, ElDialog, ElDivider, ElMessage, ElPopconfirm, ElSegmented} from 'element-plus';
import {
    backup,
    clearMessages,
    devEraseDatabase,
    expressionDump,
    getMessages,
    isOpenForReporters,
    restore,
    setOpenForReporters,
    verifyDatabase,
    tnComplianceCsvExport,
} from '@/base/callBackend';
import {CabPeriod, generateDateId, PeriodEnum} from 'common';
import {computed, onMounted, ref, watch} from 'vue';
import UnlockedIcon from '@/views/icons/unlockedIcon.vue';
import LockedIcon from '@/views/icons/LockedIcon.vue';
import {askForTag, downloadFile, downloadReportingItemsCsv} from '@/base/utils.js';

const loading          = ref();
const reportingEnabled = ref<boolean | undefined>();

const busyDownloadingReportItemsCsv = ref<boolean>(false);
const busyDownloadingTreekNormCsv   = ref<boolean>(false);
const busyDownloadingDatabase       = ref<boolean>(false);
const busyVerifyDatabase            = ref<boolean>(false);
const busyDownloadingExprDump       = ref<boolean>(false);
const busyErasingDatabase           = ref<boolean>(false);
const busyUploadingDatabase         = ref<boolean>(false);
const messages                      = ref<string[]>([]);
const treekNormDialogVisible        = ref(false);

const blockFurtherActions = computed<boolean>(() =>
    busyDownloadingReportItemsCsv.value
    || busyDownloadingTreekNormCsv.value
    || busyDownloadingDatabase.value
    || busyVerifyDatabase.value
    || busyDownloadingExprDump.value
    || busyErasingDatabase.value
    || busyUploadingDatabase.value,
);

const last8quarters: CabPeriod[] = generateListOfPastQuarters(8);

onMounted(async () => {
    try {
        loading.value          = true;
        reportingEnabled.value = await isOpenForReporters();
        messages.value         = await getMessages();
    } finally {
        loading.value = false;
    }
});

async function doDownloadReportingItemsCsv() {
    busyDownloadingReportItemsCsv.value = true;
    await downloadReportingItemsCsv(false);
    busyDownloadingReportItemsCsv.value = false;
}

async function startDownloadTreekNormComplianceCsv() {
    busyDownloadingTreekNormCsv.value = true;
    treekNormDialogVisible.value      = true;
}

async function continueDownloadTreekNormComplianceCsv(period: CabPeriod | undefined) {
    treekNormDialogVisible.value = false;
    if (period) {
        const response = await tnComplianceCsvExport(period);
        if (!response.ok) {
            const data = await new Response(response.body).json();
            ElMessage({
                message: `Failed: ${data.message}`,
                type   : 'error',
            });
        } else {
            await downloadFile(response, `${period}_ZZC-TreekNormNaleving.csv`);
        }
    }
    busyDownloadingTreekNormCsv.value = false;
}

function generateListOfPastQuarters(n: number): CabPeriod[] {
    const periods: CabPeriod[] = [];
    let currentQuarter         = CabPeriod.of(new Date(), PeriodEnum.Q);

    // Generate last 8 quarters
    for (let i = 0; i < n; i++) {
        currentQuarter = currentQuarter.previousPeriod();
        periods.push(currentQuarter);
    }
    return periods;
}

async function onDownloadDb() {
    const tag = await askForTag('Download Database Dump');
    if (tag) {
        busyDownloadingDatabase.value = true;
        const response                = await backup();
        await downloadFile(response, `zzc-${generateDateId()}-${tag}.json`);
        busyDownloadingDatabase.value = false;
    }
}

async function onVerifyDatabase() {
    busyVerifyDatabase.value = true;
    const response           = await verifyDatabase();
    busyVerifyDatabase.value = false;
    ElMessage({
        message: `Database completely synced (${response})`,
        type   : 'success',
    });
}

async function onExpressionDump() {
    busyDownloadingExprDump.value = true;
    const response                = await expressionDump();
    await downloadFile(response, `expression-dump-${generateDateId()}.json`);
    busyDownloadingExprDump.value = false;
}

async function onEraseDb() {
    busyErasingDatabase.value = true;
    await devEraseDatabase();
    busyErasingDatabase.value = false;
}

async function onUploadDb(e: MouseEvent) {
    busyUploadingDatabase.value = true;
    const input                 = document.createElement('input');
    input.type                  = 'file';
    input.style.display         = 'none';
    input.addEventListener('change', async (event: Event) => {
        const target = event.target as HTMLInputElement;
        const file   = target.files?.[0] ?? null;
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            const result = await restore(formData);
            const body   = await jsonBodyFromResponse(result);
            ElMessage({
                message: `Database completely replaced and restored (${body.message})${e.shiftKey ? '' : ', reloading in 2 sec...'}`,
                type   : 'success',
            });
            busyUploadingDatabase.value = false;
        }
    });
    document.body.appendChild(input);
    input.click();
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

async function jsonBodyFromResponse(res: Response): Promise<any> {
    let result = '';
    if (res.body != null) {
        const reader  = res.body.getReader();
        const decoder = new TextDecoder();
        while (true) {
            const {
                      done,
                      value,
                  } = await reader.read();
            if (done) {
                break;
            }
            result += decoder.decode(value);
        }
    }
    return JSON.parse(result);
}

type ReportingOption = {
    label: string;
    value: boolean;
};
const reportingOptions: Array<ReportingOption> = [
    {
        label: 'Dicht voor Reporters',
        value: false,
    },
    {
        label: 'Open voor Reporters',
        value: true,
    },
];

watch(reportingEnabled, (_is, was) => {
    if (was !== undefined) {
        setOpenForReporters(reportingEnabled.value!);
    }
});
</script>

<template>
    <div class="flex justify-center items-center mt-10">
        <div class="flex flex-col space-y-4">
            <div class="flex flex-col space-y-4 justify-center items-center p-5 border-transparent border-2">
                <el-segmented
                    v-model="reportingEnabled"
                    :options="reportingOptions"
                    :disabled="loading"
                >
                    <template #default="{ item }: { item: any }">
                        <div class="flex flex-row items-center gap-2 p-2">
                            <UnlockedIcon v-if="item.value"/>
                            <LockedIcon v-if="!item.value"/>
                            <div>{{ item.label }}</div>
                        </div>
                    </template>
                </el-segmented>

                <div class="w-full">
                    <el-divider class="border-black my-2"/>
                </div>

                <el-button type="primary" size="large"
                           @click="doDownloadReportingItemsCsv"
                           :disabled="blockFurtherActions"
                           :loading="busyDownloadingReportItemsCsv"
                           class="w-96"
                >
                    Download Reporting Items (CSV)
                </el-button>

                <el-button type="primary" size="large"
                           @click="startDownloadTreekNormComplianceCsv"
                           :disabled="blockFurtherActions"
                           :loading="busyDownloadingTreekNormCsv"
                           class="w-96 mr-3">
                    Download Treeknorm Naleving (CSV)
                </el-button>

                <el-button type="primary" size="large"
                           @click="onExpressionDump()"
                           :disabled="blockFurtherActions"
                           :loading="busyDownloadingExprDump"
                           class="w-96 mr-3"
                >
                    Download Expression Dump (JSON)
                </el-button>

                <div class="w-full">
                    <el-divider class="border-black my-2"/>
                </div>

                <el-button type="primary" size="large"
                           @click="onVerifyDatabase"
                           :disabled="blockFurtherActions"
                           :loading="busyVerifyDatabase"
                           class="w-96"
                >
                    Verify Database
                </el-button>

                <el-button type="primary" size="large"
                           @click="onDownloadDb()"
                           :disabled="blockFurtherActions"
                           :loading="busyDownloadingDatabase"
                           class="w-96 mr-3"
                >
                    Download Database Dump
                </el-button>

            </div>

            <div class="relative flex flex-col space-y-4 justify-center items-center p-5 border-orange-500 border-2">
                <el-popconfirm
                    title="Alle info in de huidige database gaat verloren. Weet je het zeker?"
                    width="450px"
                    @confirm="onEraseDb"
                    confirm-button-text="JA"
                    cancel-button-text="Stop"
                    confirm-button-type="danger"
                    :hide-icon="true"
                >
                    <template #reference>
                        <el-button type="warning" size="large"
                                   :disabled="blockFurtherActions"
                                   :loading="busyErasingDatabase"
                                   class="w-96"
                        >
                            Erase Database
                        </el-button>
                    </template>
                </el-popconfirm>

                <el-popconfirm
                    title="Alle info in de huidige database gaat verloren. Weet je het zeker?"
                    width="450px"
                    @confirm="onUploadDb"
                    confirm-button-text="JA"
                    cancel-button-text="Stop"
                    confirm-button-type="danger"
                    :hide-icon="true"
                >
                    <template #reference>
                        <el-button type="warning" size="large"
                                   :disabled="blockFurtherActions"
                                   :loading="busyUploadingDatabase"
                                   class="w-96 mr-3"
                        >
                            Upload Dump and Overwrite Database
                        </el-button>
                    </template>
                </el-popconfirm>

                <span
                    class="absolute top-[-30px] left-[170px] bg-orange-500 text-white text-sm px-4 py-0.5 rounded cursor-default">
                    Danger Zone
                </span>
            </div>
        </div>
    </div>

    <el-dialog title="Van welk kwartaal wil je de wachttijden?"
               v-model="treekNormDialogVisible"
               width="30%"
               center
               @close="continueDownloadTreekNormComplianceCsv(undefined)"
    >
        <div class="flex flex-col items-center">
            <div></div>
            <el-button
                type="primary"
                class="my-1"
                :class="item==last8quarters[0]?'ml-3':''"
                v-for="item in last8quarters"
                :key="item.uuid"
                @click="continueDownloadTreekNormComplianceCsv(item)">
                {{ item.toString() }}
            </el-button>
        </div>
    </el-dialog>

    <div class="flex flex-col justify-center items-center mt-6" v-if="1<messages.length">
        <div
            class="w-full h-80 overflow-auto border-2 my-4 mx-10 p-4 rounded bg-black border-white font-mono text-xs whitespace-nowrap text-green-400">
            <p class="p-1"
               v-for="(l, i) in messages"
               :key="i"
            >
                {{ l }}
            </p>
        </div>
        <el-button type="primary" size="large"
                   @click="clearMessages(); messages = []"
                   class="w-44 mr-3"
        >
            Clear Messages
        </el-button>

    </div>
</template>
