<script setup lang="ts">
import {ElButton} from 'element-plus';
import {downloadReportingItemsCsv} from '@/base/utils.js';
import {ref} from 'vue';

const downloading = ref<boolean>(false);

async function doDownloadCsv() {
    downloading.value = true;
    await downloadReportingItemsCsv(true);
    downloading.value = false;
}
</script>

<template>
    <div class="flex justify-center items-center mt-32">
        <div class="flex flex-col space-y-4">
            <div class="flex flex-col space-y-4 justify-center items-center p-5 border-transparent border-2">
                <el-button type="primary" size="large"
                           @click="doDownloadCsv()"
                           :disabled="downloading"
                           :loading="downloading"
                           class="w-80"
                >
                    Download alle rapportages als CSV
                </el-button>
            </div>
        </div>
    </div>
</template>
