<script setup lang="ts">
import {type PropType, ref, watch} from 'vue';
import {ElInput} from 'element-plus';
import {formatWaarde, MijlpaalEnum, parseWaarde, REPORTED_VALUE_UNKNOWN, type WaardeTypeEnum} from 'common';

const waarde = defineModel('waarde', {
    type: [Number, undefined] as PropType<number | MijlpaalEnum | undefined>,
});
const props  = defineProps({
    waardeType     : {
        type    : [Number, undefined] as PropType<WaardeTypeEnum | undefined>,
        required: true,
    },
    magOnbekend    : {
        type   : Boolean,
        default: false,
    },
    disabled       : {
        type   : Boolean,
        default: false,
    },
});

const waardeText       = ref<string>(formatWaarde(waarde.value, props.waardeType));
const isWaardeOnbekend = ref<boolean>(waarde.value === REPORTED_VALUE_UNKNOWN);
const savedForOnbekend = ref<number>();

watch(waarde, () => {
    const newWaardeText = formatWaarde(waarde.value, props.waardeType);
    if (newWaardeText !== waardeText.value) {
        waardeText.value = newWaardeText;
    }
    const newIsWaardeOnbekend = waarde.value === REPORTED_VALUE_UNKNOWN;
    if (newIsWaardeOnbekend != isWaardeOnbekend.value) {
        isWaardeOnbekend.value = newIsWaardeOnbekend;
    }
});
watch(waardeText, () => {
    const newWaarde = parseWaarde(waardeText.value);
    if (newWaarde !== waarde.value) {
        if (newWaarde !== undefined || !(waarde.value === undefined || waarde.value == REPORTED_VALUE_UNKNOWN)) {
            waarde.value = newWaarde;
        }
    }
});

function formatter(value: string): string {
    return formatWaarde(parseWaarde(value), props.waardeType);
}

function parser(value: string): string {
    return formatWaarde(parseWaarde(value), props.waardeType);
}

function determineDelta(event: KeyboardEvent) {
    switch (event.key) {
        case 'ArrowUp':
            return event.shiftKey ? 10 : 1;
        case 'ArrowDown':
            return event.shiftKey ? -10 : -1;
    }
    return 0;
}

function handleKeyDown(event: KeyboardEvent) {
    const delta = determineDelta(event);
    if (delta) {
        let currentValue = parseWaarde(waardeText.value);
        if (currentValue === undefined || currentValue === REPORTED_VALUE_UNKNOWN) {
            currentValue = 0;
        }
        waardeText.value = formatWaarde(currentValue + delta, props.waardeType);

        event.stopPropagation();
        event.preventDefault();
    }
}

function zetOnbekendClicked() {
    if (isWaardeOnbekend.value) {
        if (waarde.value !== undefined) {
            waarde.value = savedForOnbekend.value;
        }
    } else {
        if (waarde.value !== REPORTED_VALUE_UNKNOWN) {
            savedForOnbekend.value = waarde.value;
            waarde.value           = REPORTED_VALUE_UNKNOWN;
        }
    }
}

function focusInput() {
    if (waarde.value === REPORTED_VALUE_UNKNOWN) {
        waarde.value = savedForOnbekend.value;
    }
}

</script>

<template>
    <div class="waarde-invul w-44 font-bold text-right">
        <el-input
            v-model="waardeText"
            type="text"
            :formatter="formatter"
            :parser="parser"
            :disabled="disabled"
            @keydown="(e) => handleKeyDown(e as KeyboardEvent)"
            @focus="focusInput()"
        >
            <template #prepend v-if="magOnbekend && !disabled">
                <div class="w-8 text-center cursor-pointer font-bold select-none" @click="zetOnbekendClicked()"
                     title="zet de waarde op 'onbekend'">?
                </div>
            </template>
        </el-input>
    </div>
</template>

<!--suppress CssUnusedSymbol -->
<style>
.waarde-invul .el-input__inner {
    @apply text-right;
}

.el-input-group__prepend {
    padding: 0 0;
}
</style>

