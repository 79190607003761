<script setup lang="ts">
import {onMounted, type PropType, ref, watch} from 'vue';
import {
    CabKPI,
    type CabReportItem,
    enum_values,
    KpiStatusEnum,
    KpiStatusEnum_name,
    KpiStatusPrognoseEnum,
    KpiStatusPrognoseEnum_name,
    renderCabPeriod,
    StatusEnum,
    StatusEnum_color,
    WaardeTypeEnum,
} from 'common';
import {readAdminItem, updateAdminItem} from '@/base/callBackend';
import WaardeInvul from '@/views/general/WaardeInvul.vue';
import {ElInput, ElOption, ElSelect} from 'element-plus';
import StatusSelector from '@/views/general/StatusSelector.vue';
import {computed} from '@vue/runtime-core';
import Marker from '@/views/general/Marker.vue';
import {EVENT_BUS} from '@/base/EVENT_BUS.js';

//===================================================================================================================
const props = defineProps({
    reportitem: {
        type    : Object as PropType<CabReportItem>,
        required: true,
    },
});

const kpi                  = ref<CabKPI>(props.reportitem.kpi[0]);
const theUserValue         = ref<number | undefined>(props.reportitem.reportedValue);
const theCalculatedValue   = computed<number | undefined>(() => props.reportitem.calculatedValue);
const theReportedValue     = computed<number | undefined>(() => theUserValue.value ?? theCalculatedValue.value);
const theDoelwaarde        = ref(props.reportitem.doelwaarde);
const theUitkeringsbedrag  = ref(props.reportitem.uitkeringsbedrag);
const theKpiStatus         = ref<KpiStatusEnum | undefined>(props.reportitem.kpiStatus);
const thePrognose          = ref<number | undefined>(props.reportitem.prognose);
const theKpiStatusPrognose = ref<KpiStatusPrognoseEnum | undefined>(props.reportitem.kpiStatusPrognose);
const statusTarget         = ref<StatusEnum[]>([]);
const showWaardeTrace      = ref<boolean>(false);
const showGehaaldTrace     = ref<boolean>(false);

//=================================================================================================
onMounted(() => {
    statusTarget.value = [props.reportitem.status!];
});

//=================================================================================================
watch(theUserValue, (v) => {
    reportedValueChanged(v);
});
watch(theDoelwaarde, (v) => {
    doelwaardeChanged(v);
});
watch(theUitkeringsbedrag, (v) => {
    uitkeringsbedragChanged(v);
});
watch(theKpiStatus, (v) => {
    kpiStatusValueChanged(v);
});
watch(thePrognose, (v) => {
    prognoseChanged(v);
});
watch(theKpiStatusPrognose, (v) => {
    kpiStatusPrognoseChanged(v);
});
watch(statusTarget, async (nv, ov) => {
    if (ov[0]) {
        const newValue        = nv[0];
        const resultingStatus = await onStatusChange(newValue);
        if (resultingStatus !== newValue) {
            statusTarget.value = [resultingStatus];
        }
    }
});
watch(() => EVENT_BUS.red, (on) => {
    if (props.reportitem.waardeExpressionHasErrors) {
        showWaardeTrace.value = on;
    }
    if (props.reportitem.gehaaldExpressionHasErrors) {
        showGehaaldTrace.value = on;
    }
})
watch(() => EVENT_BUS.green, (on) => {
    if (!props.reportitem.waardeExpressionHasErrors) {
        showWaardeTrace.value = on;
    }
    if (!props.reportitem.gehaaldExpressionHasErrors) {
        showGehaaldTrace.value = on;
    }
})

//=================================================================================================
async function reportedValueChanged(v: number | undefined) {
    if (v !== theUserValue.value || v !== props.reportitem.reportedValue) {
        theUserValue.value             = v;
        props.reportitem.reportedValue = v;
        await onChange();
    }
}

async function kpiStatusValueChanged(v: KpiStatusEnum | undefined) {
    if (v !== theKpiStatus.value || v !== props.reportitem.kpiStatus) {
        theKpiStatus.value         = v;
        props.reportitem.kpiStatus = v;
        await onChange();
    }
}

async function prognoseChanged(v: number | undefined) {
    if (v !== thePrognose.value || v !== props.reportitem.prognose) {
        thePrognose.value         = v;
        props.reportitem.prognose = v;
        await onChange();
    }
}

async function kpiStatusPrognoseChanged(v: KpiStatusPrognoseEnum | undefined) {
    if (v !== theKpiStatusPrognose.value || v !== props.reportitem.kpiStatusPrognose) {
        theKpiStatusPrognose.value         = v;
        props.reportitem.kpiStatusPrognose = v;
        await onChange();
    }
}

function keyupOnTheKpiStatus(event: any) {
    if (event.key === 'Backspace' || event.key === 'Delete') {
        theKpiStatus.value = undefined;
        event.originalTarget.blur();
    }
}

function keyupOnTheKpiStatusPrognose(event: any) {
    if (event.key === 'Backspace' || event.key === 'Delete') {
        theKpiStatusPrognose.value = undefined;
        event.originalTarget.blur();
    }
}

async function onChange() {
    const newItem                        = await updateAdminItem(props.reportitem);
    props.reportitem.calculatedKpiStatus = newItem?.calculatedKpiStatus;
    props.reportitem.calculatedValue     = newItem?.calculatedValue;
}

function diffInStatusText(): string {
    if (!props.reportitem.kpiStatus) {
        return '';
    }
    if (!props.reportitem.calculatedKpiStatus) {
        return '';
    }
    if (props.reportitem.calculatedKpiStatus === props.reportitem.kpiStatus) {
        return ``;
    }
    return `handmatig verschilt van berekend`;
}

function diffInStatusClass(): string {
    return !!diffInStatusText() ? 'text-red-600' : '';
}

function isStatusBerekend(): boolean {
    return !!kpi.value.gehaaldExpression;
}

//===================================================================================================================
async function doelwaardeChanged(newValue?: number) {
    props.reportitem.doelwaarde = newValue;
    await onChange();
}

async function uitkeringsbedragChanged(newValue?: number) {
    props.reportitem.uitkeringsbedrag = newValue ?? 0;
    await onChange();
}

async function onStatusChange(newStatus: StatusEnum): Promise<StatusEnum> {
    props.reportitem.status = newStatus;
    await onChange();
    const newItem           = await readAdminItem(props.reportitem);
    props.reportitem.status = newItem.status;
    return newItem.status!;
}

//===================================================================================================================
function dynColor() {
    return 'bg-' + StatusEnum_color(props.reportitem.status);
}

function labelClass(first: boolean = false) {
    const w = first ? 'w-44' : 'w-28';
    return `text-xs text-right ${w} mr-2`;
}

function renderMoment() {
    return renderCabPeriod(props.reportitem.period[0]);
}
</script>

<template>
    <div class="flex flex-row items-start" :class="dynColor()">

        <!--DATE-->
        <div class="backdrop-brightness-90 font-extrabold text-center my-1 mx-1 w-32">
            {{ renderMoment() }}
        </div>

        <div class="flex flex-col w-full my-1 pr-4">

            <!--ROW -->
            <div class="flex flex-row items-center w-full my-1"
                 v-if="reportitem.showReportedValue()">

                <!--kpi: gerapporteerde waarde-->
                <div class="flex flex-row items-center">
                    <div :class="labelClass(true)">
                        gerapporteerde waarde
                    </div>
                    <template v-if="kpi.isCalculatedValue">
                        <WaardeInvul
                            v-model:waarde="theReportedValue"
                            :waarde-type="kpi.waardeType"
                            magOnbekend
                            disabled
                        />
                        <div :class="labelClass()">
                            handmatig:
                        </div>
                    </template>
                    <WaardeInvul
                        v-model:waarde="theUserValue"
                        :waarde-type="kpi.waardeType"
                        magOnbekend
                    />
                    <template v-if="kpi.isCalculatedValue">
                        <div :class="labelClass(true)">
                            berekend
                        </div>
                        <WaardeInvul
                            v-model:waarde="theCalculatedValue"
                            :waarde-type="kpi.waardeType"
                            disabled
                        />
                        <Marker
                            v-if="reportitem.waardeExpressionTraces"
                            :showRedMarker="reportitem.waardeExpressionHasErrors"
                            :showGreenMarker="!reportitem.waardeExpressionHasErrors"
                            v-on:red="showWaardeTrace = !showWaardeTrace"
                            v-on:green="showWaardeTrace = !showWaardeTrace"
                        />
                    </template>
                </div>
            </div>

            <!--ROW -->
            <div class="flex flex-row items-center w-full my-1">

                <!--KPI: gerapporteerde status-->
                <div class="flex flex-row items-center"
                     v-if="reportitem.showKpiStatus()">
                    <div :class="labelClass(true)" v-if="isStatusBerekend()">
                        gerapporteerde status
                    </div>
                    <div class="font-bold w-44"
                         :class="diffInStatusClass()"
                         :title="diffInStatusText()">
                        {{ KpiStatusEnum_name(reportitem.kpiStatus ? reportitem.kpiStatus : reportitem.calculatedKpiStatus)
                        }}
                    </div>
                </div>

                <!--KPI: handmatige status-->
                <div class="flex flex-row items-center"
                     v-if="reportitem.showKpiStatus()">
                    <div :class="labelClass()" v-if="isStatusBerekend()">
                        handmatige status
                    </div>
                    <div :class="labelClass(true)" v-else>
                        gerapporteerde status
                    </div>
                    <div class="font-bold">
                        <el-select
                            v-model="theKpiStatus"
                            placeholder="kies een optie"
                            style="min-width: 270px"
                            @keyup="keyupOnTheKpiStatus($event)"
                        >
                            <el-option
                                v-for="item in enum_values(KpiStatusEnum)"
                                :key="item"
                                :label="KpiStatusEnum_name(item)"
                                :value="item"
                            />
                        </el-select>
                    </div>
                </div>

                <!--KPI: berekende status-->
                <div class="flex flex-row items-center"
                     v-if="reportitem.showCalculatedKpiStatus()">
                    <div :class="labelClass()">
                        berekende status
                    </div>
                    <div class="font-bold w-44">
                        {{ KpiStatusEnum_name(reportitem.calculatedKpiStatus) }}
                    </div>
                    <Marker
                        v-if="reportitem.gehaaldExpressionTraces"
                        :showRedMarker="reportitem.gehaaldExpressionHasErrors"
                        :showGreenMarker="!reportitem.gehaaldExpressionHasErrors"
                        v-on:red="showGehaaldTrace = !showGehaaldTrace"
                        v-on:green="showGehaaldTrace = !showGehaaldTrace"
                    />
                </div>

            </div>

            <!--ROW -->
            <div class="flex flex-row items-center w-full my-1"
                 v-if="reportitem.showPrognose()">

                <!--kpi: prognose-->
                <div class="flex flex-row items-center">
                    <div :class="labelClass(true)">
                        prognose
                    </div>
                    <WaardeInvul
                        v-model:waarde="thePrognose"
                        :waarde-type="kpi.waardeType"
                        mag-onbekend
                    />
                </div>

                <!--KPI: prognose status-->
                <div class="flex flex-row items-center"
                     v-if="reportitem.showKpiStatusPrognose()">
                    <div :class="labelClass()">
                        met status
                    </div>
                    <div class="font-bold">
                        <div class="">
                            <el-select
                                v-model="theKpiStatusPrognose"
                                placeholder="kies een optie"
                                style="min-width: 270px"
                                @keyup="keyupOnTheKpiStatusPrognose($event)"
                            >
                                <el-option
                                    v-for="item in enum_values(KpiStatusPrognoseEnum)"
                                    :key="item"
                                    :label="KpiStatusPrognoseEnum_name(item)"
                                    :value="item"
                                />
                            </el-select>
                        </div>
                    </div>
                </div>

            </div>

            <!--ROW:opmerking-->
            <div class="flex flex-row items-center w-full my-1">
                <div :class="labelClass(true)">
                    opmerking
                </div>

                <div class="flex-1"
                     title="opmerking">
                    <el-input
                        v-model="reportitem.opmerking"
                        @input="onChange()"
                        type="textarea"
                        :autosize="{ minRows: 1, maxRows: 5 }"
                    />
                </div>
            </div>

            <!--ROW: mitigatie-->
            <div class="flex flex-row items-center w-full my-1"
                 v-if="reportitem.showMitigatie()">
                <div :class="labelClass(true)">
                    mitigatie
                </div>

                <div class="flex-1"
                     title="mitigatie">
                    <el-input
                        v-model="reportitem.mitigatie"
                        @input="onChange()"
                        type="textarea"
                        :autosize="{ minRows: 1, maxRows: 5 }"
                    />
                </div>
            </div>

            <div class="flex flex-row items-center w-full my-1"
                 v-if="reportitem.showDoelwaarde(true) || reportitem.showUitkeringsbedrag(true) || reportitem.showVecozoId(true)">
                <div :class="labelClass(true)">
                    admin
                </div>

                <div class="flex-1 py-2 mt-2 border border-gray-400">

                    <!--ROW -->
                    <div class="flex flex-row items-center w-full my-1">

                        <!--kpi: doelwaarde-->
                        <div class="flex flex-row items-center"
                             v-if="reportitem.showDoelwaarde(true)">
                            <div :class="labelClass()">
                                doelwaarde
                            </div>
                            <div class="font-bold">
                                <WaardeInvul
                                    v-model:waarde="theDoelwaarde"
                                    :waarde-type="kpi.waardeType"
                                />
                            </div>
                        </div>
                    </div>

                    <!--ROW: uitkering/vecozo-->
                    <div class="flex flex-row items-center w-full my-1"
                         v-if="reportitem.showUitkeringsbedrag(true) || reportitem.showVecozoId(true)">

                        <!--uitkeringsbedrag-->
                        <div class="flex flex-row items-center"
                             v-if="reportitem.showUitkeringsbedrag(true)">
                            <div :class="labelClass()">
                                uitkeringsbedrag
                            </div>

                            <div class="font-bold">
                                <WaardeInvul
                                    v-model:waarde="theUitkeringsbedrag"
                                    :waarde-type="WaardeTypeEnum.bedrag"
                                />
                            </div>
                        </div>

                        <!--vecozo id-->
                        <div class="flex flex-row items-center"
                             v-if="reportitem.showVecozoId(true)">
                            <div :class="labelClass()">
                                vecozo id
                            </div>
                            <div class="font-bold">
                                <input
                                    class="px-2 py-1 border-1 w-full"
                                    v-model="reportitem.vecozoId"
                                    type="text"
                                    style="max-width: 170px"
                                    @input="onChange"
                                />
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <div class="flex flex-col items-stretch w-full">

                <div class="flex flex-col items-stretch w-full overflow-hidden">
                    <div class="overflow-y-auto max-h-64 m-2 border-2 border-black"
                         :class="reportitem.waardeExpressionHasErrors?'bg-red-200':'bg-green-200'"
                         v-if="(kpi.waardeExpression||kpi.isCalculatedValue) && showWaardeTrace"
                         :title="kpi.waardeExpression"
                    >
                        <div class="font-bold pl-2">
                            waarde:
                        </div>
                        <div class="overflow-x-auto whitespace-nowrap text-xs pl-4 pb-2 pr-2 pt-1">
                            <pre v-for="line of reportitem.waardeExpressionTraces.split('\n')">{{ line }}</pre>
                        </div>
                    </div>

                    <div class="overflow-y-auto max-h-64 m-2 border-2 border-black"
                         :class="reportitem.gehaaldExpressionHasErrors?'bg-red-200':'bg-green-200'"
                         v-if="kpi.gehaaldExpression && showGehaaldTrace"
                         :title="kpi.gehaaldExpression"
                    >
                        <div class="font-bold pl-2">
                            gehaald:
                        </div>
                        <div class="overflow-x-auto whitespace-nowrap text-xs pl-4 pb-2 pr-2 pt-1">
                            <pre v-for="line of reportitem.gehaaldExpressionTraces.split('\n')">{{ line }}</pre>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="m-2">
            <StatusSelector :multi="false"
                            v-model:statusSet="statusTarget"
            />
        </div>
    </div>
</template>
