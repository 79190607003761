<script setup lang="ts">

const RED_MARKER   = 'red';
const GREEN_MARKER = 'green';

const emits = defineEmits([RED_MARKER, GREEN_MARKER]);
defineProps({
    showRedMarker  : {
        type   : Boolean,
        default: false,
    },
    showGreenMarker: {
        type   : Boolean,
        default: false,
    },
});

function onMarker(which: typeof RED_MARKER | typeof GREEN_MARKER) {
    emits(which);
}

</script>

<template>
    <div class="flex flex-row items-center">
        <div v-if="showRedMarker"
             class="m-1 cursor-pointer"
             @click="onMarker(RED_MARKER)">
            🟥
        </div>
        <div v-if="showGreenMarker"
             class="m-1 cursor-pointer"
             @click="onMarker(GREEN_MARKER)">
            🟩
        </div>
    </div>
</template>
