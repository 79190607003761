import {CabObj} from './index';

export class Filter {
    public static readonly INACTIVE = new Filter(false, '');

    private readonly active: boolean;
    private readonly isNegated: boolean;
    private readonly isRegexp: boolean;
    private readonly text: string;
    private readonly ignoreCaseRegexp: RegExp;

    public constructor(active: boolean, s: string) {
        this.active           = active;
        this.isNegated        = s.startsWith('!');
        this.isRegexp         = s.startsWith('~');
        this.text             = this.isNegated || this.isRegexp ? s.substring(1) : s;
        this.ignoreCaseRegexp = new RegExp(this.isRegexp ? this.text : Filter.escapeRegExp(this.text), 'i');
    }

    public match(arg: string | number | undefined) {
        if (!this.active) {
            return true;
        }

        let b = false;
        if (typeof arg === 'string') {
            b = this.ignoreCaseRegexp.test(arg);
        } else if (typeof arg === 'number') {
            b = this.ignoreCaseRegexp.test(arg.toString());
        }
        return b !== this.isNegated;
    }

    public some<T extends CabObj>(l: T[], limited: boolean = false): boolean {
        return l.some(e => e.passes(this, limited));
    }

    public filter<T extends CabObj>(l: T[]): T[] {
        return !this.active ? l : l.filter(o => o.passes(this));
    }

    public toString(): string {
        return `Filter[${this.active ? '' : 'disabled,'}${this.isNegated ? '!' : ''}${this.text}]`;
    }

    private static escapeRegExp(s: string) {
        return s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }
}
