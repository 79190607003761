<script setup lang="ts">
import * as Session from 'supertokens-web-js/recipe/session';
import {onMounted, ref} from 'vue';
import {useRouter} from 'vue-router';
import type {CabMe} from 'common';
import {getMeInfo} from '@/base/me.js';

const router = useRouter();

const meInfo = ref<CabMe | undefined>(undefined);

onMounted(async () => {
    meInfo.value = await getMeInfo();
});

async function onLogout() {
  await Session.signOut();
  await router.push('/');
}

function getUserName() {
  const f = meInfo.value?.first_name;
  const l = meInfo.value?.last_name;
  if (f || l) {
    return `${f} ${l}`;
  } else {
    return meInfo.value?.emails?.[0];
  }
}

function getUserPermission() {
  return meInfo.value?.permissions?.join();
}
</script>

<template>
  <div class="flex flex-col p-2 m-2 bg-gray-100 min-w-60 space-y-1">
    <div class="text-xs text-gray-600 flex-1 text-center">
      {{ getUserName() }}
    </div>
    <div class="text-xs text-gray-600 flex-1 text-center">
      {{ getUserPermission() }}
    </div>
    <button
        class="w-full bg-green-600 hover:bg-green-700 text-white text-xs font-bold py-1 px-2 rounded cursor-pointer"
        @click="onLogout"
    >
      Logout
    </button>
  </div>
</template>
