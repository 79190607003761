export enum StatusEnum {
    future = 1,
    open,
    overdue,
    submitted,
    accepted,
    rejected
}

export enum PeriodEnum {
    Q = 1,
    H,
}

export enum OnderwerpEnum {
    mijlpaal = 1,
    adherentie,
    financien,
    personeel,
    productiviteit,
    quadrupleAim,
    toegankelijkheid,
    volumes,
    proces   = 1, // extra alias
}

export enum KPITypeEnum {
    input = 1,
    output,
    onderliggend,
}

export enum PrognoseSchaalEnum {
    none = 1,
    once,
    all,
}

export enum WaardeTypeEnum {
    getal = 1,
    percentage,
    bedrag,
    decimaal,
}

export enum MijlpaalEnum {
    gehaald = 1,
    gemist,
    onbekend,
    gestart,
}

export enum KpiStatusEnum {
    gehaald = 1,
    gemist,
    onbekend
}

export enum MijlpaalPrognoseEnum {
    wordtGehaald = 1,
    kansOpMissen,
    wordtGemist
}

export enum KpiStatusPrognoseEnum {
    wordtGehaald = 1,
    kansOpMissen,
    wordtGemist
}

export enum WachttijdApiTypeEnum {
    MSZ = 1,
    GGZ,
}

export enum UnavailabilityReasonEnum {
    insufficientObservations = 1,
    aanmeldstop,
    unknown,
}

export type TreekNormCategory = 'Diagnostiek' | 'Polikliniekbezoek' | 'Behandeling' | 'GGZ_AanmeldGesprek' | 'GGZ_Behandeling';

