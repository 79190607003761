<script setup lang="ts">
import {ElBadge, ElCheckboxButton, ElCheckboxGroup, ElSkeleton, ElSkeletonItem} from 'element-plus';
import {computed, PropType, ref, watch} from 'vue';
import {CabReportItem} from 'common';
import {localStorageGetJson, localStorageSetJson, SelectGroupInfo} from '@/base/utils.js';
import {done} from '@/base/loadingState.js';

const emit  = defineEmits(['update:selectedList']);
const props = defineProps({
    all             : {
        type    : Array as PropType<CabReportItem[]>,
        required: true,
    },
    names           : {
        type    : Array as PropType<String[]>,
        required: true,
    },
    localStorageName: {
        type    : String,
        required: true,
    },
    selectField     : {
        type    : Function as PropType<(a: CabReportItem) => SelectGroupInfo>,
        required: true,
    },
    showBadges: {
        type    : Boolean,
        required: true,
    },
    getBadge        : {
        type    : Function as PropType<(a: string | number) => number>,
        required: true,
    },
    selectedList    : {
        type    : Array as PropType<(string | number)[]>,
        required: true,
    },
});

const map            = computed<Map<string | number, SelectGroupInfo>>(() => new Map(props.all.map((item: CabReportItem) => [props.selectField(item).id, props.selectField(item)])));
const list           = computed<(string | number)[]>(() => Array.from(map.value.values()).sort((a, b) => a.name.localeCompare(b.name)).map(e => e.id));
const selectedList   = ref<(string | number)[]>([]);
const selectedInited = ref<boolean>(false);
const onlyOne        = computed<boolean>(() => map.value.size === 1);
watch(selectedList, newVal => {
    emit('update:selectedList', newVal);
    localStorageSetJson(props.localStorageName, newVal);
});
watch(() => props.selectedList, newVal => {
    selectedList.value = newVal;
});
watch(list, newVal => {
    if (!selectedInited.value) {
        if (newVal.length <= 1) {
            selectedList.value = newVal;
        } else {
            const savedArray: string[] = localStorageGetJson(props.localStorageName);
            let restoreList            = newVal;
            if (savedArray && savedArray.length == 0) {
                restoreList = [];
            }
            if (savedArray && savedArray.some(s => list.value.includes(s))) {
                // keep only the one currently in the list
                restoreList = savedArray.filter((id: string | number) => newVal.includes(id));
            }
            selectedList.value = restoreList;
        }
        selectedInited.value = true;
    }
});

</script>

<template>
    <div class="flex flex-row px-20 w-full h-16">
        <div class="w-44">
            <div class="pl-2 pr-8 py-2 my-1 text-right font-thin cursor-pointer"
                 @click="selectedList = selectedList.length===list.length && list.length!==1 ? [] : list">
                {{ names[onlyOne ? 0 : 1] }}
            </div>
        </div>

        <div class="w-full py-2">
            <div v-if="onlyOne" class="pt-1">
                {{ map.get(list[0])?.name }}
            </div>

            <el-skeleton class="w-full" animated v-if="!done">
                <template #template>
                    <el-skeleton-item variant="rect" animated style="width: 340px; height: 34px"/>
                </template>
            </el-skeleton>

            <template v-if="!onlyOne && done">
                <el-checkbox-group v-model="selectedList" class="flex">
                    <el-checkbox-button :value="uuid" v-for="uuid in list"
                                        :key="uuid"
                                        border
                    >
                        <el-badge
                            :value="getBadge(uuid)"
                            :max="99"
                            :offset="[5,-12]"
                            badge-class="font-normal"
                            :show-zero="false"
                            :hidden="!showBadges"
                        >
                            {{ map.get(uuid)?.name }}
                        </el-badge>
                    </el-checkbox-button>
                </el-checkbox-group>
            </template>
        </div>
    </div>
</template>
