<script setup lang="ts">
import {getAppVersion} from '@/base/frontendConfig.js';
import {todo} from '@/base/callBackend.js';
import {ref} from 'vue';
import MarkdownIt from 'markdown-it';
import taskLists from 'markdown-it-task-lists';
import {ElDialog} from 'element-plus';

const props = defineProps({
  todo: {
    type   : Boolean,
    default: false,
  },
});

const featureDialogVisible = ref(false);
const content              = ref('');
const md                   = new MarkdownIt().use(taskLists);

async function showTodo() {
  if (props.todo) {
    content.value              = await todo();
    featureDialogVisible.value = true;
  }
}

</script>

<template>
  <div class="z-50 absolute top-[12px] left-[250px] px-2 text-xs text-center"
       :class="props.todo?'cursor-pointer':'cursor-default'"
       @click="showTodo()"
  >
    {{ getAppVersion() }}
  </div>

  <el-dialog
      v-model="featureDialogVisible"
      :show-close="false"
      :close-on-press-escape="true"
      :close-on-click-modal="true"
  >
    <template #header>
      <div class="text-xl text-center w-full bg-gray-700 text-gray-200 py-2">
        {{ getAppVersion() }}
      </div>
    </template>
    <div class="flex flex-col min-w-60 bg-gray-300 p-3">
      <div class="prose">
        <div v-html="md.render(content)"></div>
      </div>
    </div>
  </el-dialog>
</template>
