<script setup lang="ts">

import {LoadingState, timeErrorMessage} from '@/base/loadingState.js';
import {ElLoading} from 'element-plus';
import {onMounted, watch} from 'vue';

const props = defineProps<{
    state: LoadingState;
}>();

onMounted(async () => {
    onStateChange();
});

watch(() => props.state, () => {
    onStateChange();
});

let loading: ReturnType<typeof ElLoading.service> | undefined;

function onStateChange() {
    if (props.state === LoadingState.LOADING) {
        loading = ElLoading.service({
            lock      : true,
            text      : 'Loading...',
            background: 'rgba(100, 100, 100, 0.5)',
            svg       : `
<svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="25"
      cy="25"
      r="20"
      fill="none"
      stroke="black"
      stroke-width="6"
      stroke-linecap="round"
      stroke-dasharray="100"
      stroke-dashoffset="0">
    </circle>
  </svg>`,
        });
    } else if (loading) {
        loading.close();
    }
}

</script>

<template>
    <div v-if="state===LoadingState.TIME_ERROR" class="text-xl text-center w-full">
        Helaas, de tijd op je device wijkt teveel af van de server.
        <div class="flex flex-col items-center justify-center my-20">
            <div class="text-sm p-2 m-4 bg-amber-300 w-[400px] border border-gray-500 ">
                <div class="font-bold">
                    tech-info
                </div>
                <div class="p-2" v-html="timeErrorMessage"></div>
            </div>
        </div>
    </div>

    <div v-else-if="state===LoadingState.PERMISSION_ERROR" class="text-xl text-center w-full">
        Huh, je mist de nodige permissies...
    </div>

    <div v-else-if="state===LoadingState.GENERAL_ERROR" class="text-xl text-center w-full">
        Ach, er ging iets mis...
    </div>

    <div v-else-if="state===LoadingState.CLOSED" class="text-xl text-center w-full">
        Op dit moment is deze site gesloten.<br>
        Er kunnen geen rapportages worden ingezien of ingediend.<br>
        Voor vragen kunt u contact opnemen met Popke Rein Munniksma van ZZC.
    </div>
</template>

<style>
/*noinspection CssUnusedSymbol*/
.el-loading-spinner .el-loading-text {
    color: black;
    font-size: 24px;
}
</style>
