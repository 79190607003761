type ChangeEntry<T> = {
    id: string;
    action: () => Promise<T>;
    resolve: (value: T) => void;
    reject: (reason: any) => void;
};

const QUEUE: ChangeEntry<any>[] = [];
let processing: boolean         = false;

export async function debounce<T>(id: string, action: () => Promise<T>): Promise<T> {
    // remove entries with this id
    const index = QUEUE.findIndex(entry => entry.id === id);
    if (index !== -1) {
        QUEUE.splice(index, 1);
    }

    // Wrap the action in a typed Promise and add it to the queue
    return new Promise<T>(async (resolve, reject) => {
        QUEUE.push({
            id,
            action,
            resolve,
            reject,
        });

        // Inline the processQueue logic
        if (!processing && QUEUE.length > 0) {
            processing = true;

            while (QUEUE.length > 0) {
                const {
                          action,
                          resolve,
                          reject,
                      } = QUEUE.shift()!; // Take the next task
                try {
                    resolve(await action()); // Execute the action and resolve the promise with its typed result
                } catch (error) {
                    reject(error); // Reject the promise on error
                }
            }

            processing = false;
        }
    });
}
