//=========================== common utils (backend/frontend:
// noinspection JSUnusedGlobalSymbols

import short from 'short-uuid';
import {v4} from 'uuid';
import {plainToInstance, Type} from 'class-transformer';
import 'reflect-metadata';
import {sprintf} from 'sprintf-js';
import {KpiStatusEnum, KpiStatusPrognoseEnum, KPITypeEnum, MijlpaalEnum, MijlpaalPrognoseEnum, OnderwerpEnum, PeriodEnum, PrognoseSchaalEnum, StatusEnum, TreekNormCategory, UnavailabilityReasonEnum, WaardeTypeEnum, WachttijdApiTypeEnum} from './enums&types';
import {Filter} from './Filter';
import {KpiStatusEnum_name, KpiStatusPrognoseEnum_name, MijlpaalEnum_name, MijlpaalPrognoseEnum_name, OnderwerpEnum_name, PeriodEnum_char, StatusEnum_name, VoorspellingsSchaalEnum_name} from './rendering';

export * from './traceflags';
export * from './enums&types';
export * from './rendering';

export * from './Filter';

export function nextUUID() {
    return short().fromUUID(v4());
}

export const JSON_MARKER: string = '𝐉𝐒𝐎𝐍';
export const DATE_MARKER: string = `${JSON_MARKER}:Date:`;

export function JSONstringify(o: any) {
    const saved = Date.prototype.toJSON;
    try {
        // @ts-ignore
        Date.prototype.toJSON = function () {
            return JsonStringFromDate(this);
        };
        return JSON.stringify(o, replacer);
    } finally {
        Date.prototype.toJSON = saved;
    }
}

export function JSONparse(txt: string) {
    return JSON.parse(txt, reviver);
}

export function replacer(_key: string, value: any): any {
    if (Array.isArray(value)) {
        return value.map(v => replacer('', v));
    } else if (value instanceof Date) {
        return JsonStringFromDate(value);
    } else {
        return value;
    }
}

export function reviver(_key: string, s: any) {
    if (s && typeof s === 'string') {
        const d = JsonDateFromString(s);
        if (d) {
            return d;
        }
    }
    return s;
}

function JsonStringFromDate(d: Date) {
    return `${DATE_MARKER}${d.getTime()}:${d.toUTCString()}`;
}

function JsonDateFromString(s: string) {
    if (!s.startsWith(DATE_MARKER)) {
        return undefined;
    }
    const s1 = s.replace(new RegExp(`^${DATE_MARKER}`), '').replace(/:.*/, '');
    return new Date(+s1);
}

export function renderFullDateUTC(d: Date): string {
    return sprintf('%04d-%02d-%02d %02d:%02d:%02d %03d', d.getUTCFullYear(), d.getUTCMonth() + 1, d.getUTCDay(), d.getUTCHours(), d.getUTCMinutes(), d.getUTCSeconds(), d.getUTCMilliseconds());
}

export function renderFullDateLocal(d: Date): string {
    return sprintf('%04d-%02d-%02d %02d:%02d:%02d.%03d', d.getFullYear(), d.getMonth() + 1, d.getDay(), d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds());
}

export function renderTerseDate(d: Date | undefined): string {
    if (d === undefined) {
        return '<undef>';
    } else {
        return sprintf('%04d-%02d', d.getUTCFullYear(), d.getUTCMonth() + 1);
    }
}

export const REPORTED_VALUE_UNKNOWN: number = -0x0FFF_FFFF_FFF0;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function classnameOf<T extends CabObj>(o: T) {
    if (!o) {
        throw new Error('trying to get the classname of <undefined>');
    }
    const classname = o.classname;
    if (!classname) {
        console.error('object has no classname', o);
        throw new Error(`object has no classname ${JSONstringify(o)}`);
    }
    return classname;
}

export function uuidOf<T extends CabObj>(o: T | undefined) {
    if (!o) {
        throw new Error('trying to get a uuid of <undefined>');
    }
    const uuid = o.uuid;
    if (!uuid) {
        console.error('object has no uuid', o);
        throw new Error(`object has no uuid ${JSONstringify(o)}`);
    }
    return uuid;
}

export function isInSync<T extends CabObj>(o: T | undefined): boolean {
    if (!o) {
        throw new Error('trying to get insync of <undefined>');
    }
    return !!o.volatile$insync;
}

export function setInSync<T extends CabObj>(o: T | undefined): boolean {
    if (isInSync(o)) {
        return false;
    }
    o!.volatile$insync = Date.now();
    return true;
}

export function clrInSync<T extends CabObj>(o: T | undefined): void {
    o!.volatile$insync = undefined;
}

export function isVolatileProperty(p: string) {
    return p.startsWith('volatile');
}

function preventNameMangle<T extends new (...args: any[]) => any>(_constructor: T) {
    _constructor.prototype.className = _constructor.name;
}

const CAB_SINGLE_REF: string                               = 'CabSingleRef';
const CAB_MULTI_REF: string                                = 'CabMultiRef';
const CAB_NAME_TO_CLASS: Map<string, CabObjCreate<CabObj>> = new Map<string, CabObjCreate<CabObj>>();

function CabSingleRef(target: any, propertyKey: string) {
    Reflect.defineMetadata(CAB_SINGLE_REF, true, target, propertyKey);
}

function CabMultiRef(target: any, propertyKey: string) {
    Reflect.defineMetadata(CAB_MULTI_REF, true, target, propertyKey);
}

export function cabIsSingleRef(classname: string, fieldName: string): boolean {
    return Reflect.hasMetadata(CAB_SINGLE_REF, name2class(classname).prototype, fieldName);
}

export function cabIsMultiRef(classname: string, fieldName: string): boolean {
    return Reflect.hasMetadata(CAB_MULTI_REF, name2class(classname).prototype, fieldName);
}

export function cabIsRef(classname: string, fieldName: string): boolean {
    return cabIsSingleRef(classname, fieldName) || cabIsMultiRef(classname, fieldName);
}

export type CabObjCreate<T extends CabObj> = new (uuid?: string) => T;

function registerClass<T extends CabObj>(cls: CabObjCreate<T>) {
    CAB_NAME_TO_CLASS.set(cls.name, cls);
}

export function allClassNames(): string[] {
    return [...CAB_NAME_TO_CLASS.keys()];
}

export function name2class<T extends CabObj>(name: string) {
    const c: CabObjCreate<T> | undefined = CAB_NAME_TO_CLASS.get(name) as CabObjCreate<T> | undefined;
    if (!c) {
        console.log(`unknown class name: ${name}, ${CAB_NAME_TO_CLASS.size} known:`);
        for (const name in CAB_NAME_TO_CLASS) {
            console.log(`  - ${name}`);
        }
        throw new Error(`unknown class name: ${name}`);
    }
    return c;
}

export function newByClassname<T extends CabObj>(name: string, uuid: string): T {
    const bClass: CabObjCreate<T> = name2class(name);
    return new bClass(uuid);
}

export function isObject(value: unknown): value is Record<string, unknown> {
    return Object.prototype.toString.call(value) === '[object Object]';
}

export function isDate(value: unknown): value is Date {
    return Object.prototype.toString.call(value) === '[object Date]';
}

export function isCabObject<T extends CabObj>(value: unknown): value is T {
    return isObject(value) && 'classname' in value && 'uuid' in value;
}

export function isCabArray<T extends CabObj>(a: unknown): a is T[] {
    return Array.isArray(a) && a.every(e => isCabObject(e));
}

export function toCabList<T extends CabObj>(l: T[]): T[] {
    return l.map(t => toCab(t));
}

export function toCab<T extends CabObj>(t: T): T {
    return toCab_(t.classname, t) as T;
}

export function toCab_<T extends CabObj>(classname: string, t: T): T {
    return plainToInstance(name2class(classname), t) as T;
}

export function toCabList_<T extends CabObj>(classname: string, l: T[]): T[] {
    return l.map(t => toCab_(classname, t));
}

export function makeUniqueList<T extends CabObj>(l: T[]): T[] {
    const ll: T[]            = [];
    const uuids: Set<string> = new Set();
    for (const o of l) {
        const uuid = uuidOf(o);
        if (!uuids.has(uuid)) {
            uuids.add(uuid);
            ll.push(o);
        }
    }
    ll.sort((a, b) => a.compare(b));
    return ll;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function enum_values<ENUM extends Record<string, unknown>>(ee: ENUM): number[] {
    return Object.values(ee)
        .filter(v => typeof v === 'number')
        .map(v => v as number);
}

export function enum_name_map<ENUM extends Record<string, unknown>>(ee: ENUM): Map<number, string> {
    return new Map(Object.entries(ee)
        .filter(([_k, v]) => typeof v === 'number')
        .map(([k, v]) => [v as number, k]));
}

export function diffTimeSpans(a: Date[], b: Date[]): boolean {
    return diffDates(a[0], b[0]) || diffDates(a[1], b[1]);
}

export function diffDates(a: Date, b: Date): boolean {
    return !sameDates(a, b);
}

export function sameDates(a: Date, b: Date): boolean {
    return compareDates(a, b) === 0;
}

export function compareDates(a: Date, b: Date): number {
    return a.getTime() - b.getTime();
}

export function diffPeriods(a: CabPeriod, b: CabPeriod): boolean {
    return !samePeriods(a, b);
}

export function samePeriods(a: CabPeriod, b: CabPeriod): boolean {
    return a.unit === b.unit && comparePeriods(a, b) === 0;
}

export function comparePeriods(a: CabPeriod, b: CabPeriod): number {
    return a.compare(b);
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const QUARTERS_PER_YEAR: number  = 4;
export const HALVES_PER_YEAR: number    = 2;
export const MONTHS_PER_QUARTER: number = 12 / QUARTERS_PER_YEAR;
export const MONTHS_PER_HALF: number    = 12 / HALVES_PER_YEAR;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export class CabObj {
    classname: string;
    uuid: string;
    volatile$insync?: number;

    constructor(uuid: string | undefined = undefined) {
        this.classname = this.constructor.name;
        this.uuid      = uuid ? uuid : nextUUID();
    }

    compare<T extends CabObj>(_that: T): number {
        throw new Error(`compare() should be implemented in ${this.constructor.name}`);
    }

    isInconsistent(): Error | undefined {
        return undefined;
    }

    passes(_filter: Filter, _limited: boolean = false): boolean {
        return false;
    }
}

export class CabNamedObj extends CabObj {
    name: string = '';

    compare<T extends CabObj>(that: T): number {
        if (!(that instanceof CabNamedObj)) {
            throw new Error(`compare() can only compare CabNamedObj's, refactor ${that.constructor.name}`);
        }
        return this.name.localeCompare((that as CabNamedObj).name);
    }

    passes(filter: Filter, limited: boolean = false): boolean {
        return super.passes(filter, limited)
            || filter.match(this.name);
    }
}

export function cabCompare<T extends CabObj>(a: T, b: T): number {
    return a.compare(b);
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@preventNameMangle
export class CabMe {
    userid: string                = '';
    first_name?: string;
    last_name?: string;
    emails?: string[];
    timeJoined?: number;
    phoneNumbers?: string[];
    thirdParty?: {
        id: string;
        userId: string;
    }[];
    roles?: string[];
    permissions?: string[];
    serverTimestamp: number       = 0;
    serverBehindLocalTime: number = 0;
}

@preventNameMangle
export class CabUser extends CabNamedObj {
    email?: string;
    @CabMultiRef
    @Type(() => CabOrganization)
    organizations: CabOrganization[] = [];
    @CabMultiRef
    @Type(() => CabTheme)
    themes: CabTheme[]               = [];

    getOrgThemeCombiUuids(): string[][] {
        return Object.entries(this.organizations).map(([key, org]) => [
            uuidOf(org),
            uuidOf(this.themes[+key]),
        ]);
    }

    passes(filter: Filter, limited: boolean = false): boolean {
        return super.passes(filter, limited)
            || filter.match(this.email)
            || filter.some(this.themes, limited)
            || filter.some(this.organizations, limited)
            ;
    }
}

@preventNameMangle
export class CabTheme extends CabNamedObj {
}

@preventNameMangle
export class CabOrganization extends CabNamedObj {
    @CabMultiRef
    @Type(() => CabTheme)
    themes: CabTheme[] = [];
    kvkNumber?: string;
    wachttijdApiType?: WachttijdApiTypeEnum;

    passes(filter: Filter, limited: boolean = false): boolean {
        return super.passes(filter, limited)
            || filter.some(this.themes, limited)
            || filter.match(this.kvkNumber)
            ;
    }
}

const INITIAL_DATE = Date.UTC(2023, 1, 1);

@preventNameMangle
export class CabKPI extends CabNamedObj {
    //###### MIJLPAAL EN KPI ##################################################################################################
    @CabMultiRef
    @Type(() => CabOrganization)
    organizations: CabOrganization[] = [];

    @CabMultiRef
    @Type(() => CabTheme)
    themes: CabTheme[] = [];

    onderwerp?: OnderwerpEnum;              // if == process => mijlpaal, anders kpi
    omschrijving: string = '';
    prognoseSchaal?: PrognoseSchaalEnum;    // mijlpaal: always once
    hasUitkeringsbedrag?: boolean;          // mijlpaal: always true

    //###### ALLEEN MIJLPAAL ##################################################################################################
    eersteMeetMoment?: Date;

    //###### ALLEEN KPI #######################################################################################################
    reportingPeriod?: PeriodEnum;
    timeSpan?: Date[];
    waardeType?: WaardeTypeEnum;
    isCalculatedValue: boolean = false;
    hasDoelwaarde: boolean     = false;
    askKpiStatus: boolean      = false;
    waardeExpression?: string;              // expression returning a number
    gehaaldExpression?: string;             // expression returning a boolean
    waardeExpressionErrors?: string;
    gehaaldExpressionErrors?: string;

    //#########################################################################################################################
    static initialEersteMeetMoment() {
        return new Date(INITIAL_DATE);
    }

    static sanatizeEersteMeetMoment(d: Date) {
        const y       = d.getUTCFullYear();
        const m       = Math.floor((d.getUTCMonth()) / MONTHS_PER_HALF) * MONTHS_PER_HALF;
        const newDate = new Date(Date.UTC(y, m, 1));
        return diffDates(d, newDate) ? newDate : undefined;
    }

    static initialTimeSpan(p: PeriodEnum): Date[] {
        const start: Date       = new Date(INITIAL_DATE);
        const end: Date         = new Date(INITIAL_DATE + 24 * 60 * 60 * 1000);
        const dateRange: Date[] = [start, end];
        return CabKPI.sanatizeTimeSpan(dateRange, p) ?? dateRange;
    }

    static sanatizeTimeSpan(span: Date[], p: PeriodEnum): Date[] | undefined {
        if (span.length != 2) {
            throw new Error(`can only set the timespan to a 2 Date array: ${span}`);
        }
        const s0 = span[0];
        const s1 = span[1];
        if (s1 < s0) {
            console.error(`illegal Date range: [${renderTerseDate(s0)}...${renderTerseDate(s1)}]`, span);
            throw new Error(`illegal Date range: [${renderTerseDate(s0)}...${renderTerseDate(s1)}]`);
        }
        const d0           = CabPeriod.of(s0, p).beginDate();
        const dayBeforeEnd = new Date(s1.getUTCFullYear(), s1.getUTCMonth(), s1.getUTCDate(), s1.getUTCHours(), s1.getUTCMinutes(), s1.getUTCSeconds() - 1);
        const d1           = CabPeriod.of(dayBeforeEnd, p).endDate();
        const newSpan      = [d0, d1];
        return diffTimeSpans(span, newSpan) ? newSpan : undefined;
    }

    isMijlpaal() {
        return this.onderwerp === OnderwerpEnum.mijlpaal;
    }

    getReportingPeriod(): PeriodEnum {
        if (this.isMijlpaal()) {
            throw new Error('reportingPeriod cannot be used in mijlpaal KPI');
        }
        return this.reportingPeriod ? this.reportingPeriod : PeriodEnum.Q;
    }

    setReportingPeriod(p?: PeriodEnum): boolean {
        if (this.isMijlpaal()) {
            throw new Error('reportingPeriod cannot be used in mijlpaal KPI');
        }
        const prePeriod: PeriodEnum = this.getReportingPeriod();
        const preTimeSpan: Date[]   = this.getTimeSpan();
        if (p) {
            if (prePeriod !== p) {
                this.reportingPeriod = p;
                this.setTimeSpan(preTimeSpan);
                return true;
            }
        } else if (this.reportingPeriod) {
            this.reportingPeriod = undefined;
            this.setTimeSpan(preTimeSpan);
            return true;
        }
        return false;
    }

    getTimeSpan(): Date[] {
        if (this.isMijlpaal()) {
            throw new Error('timeSpan cannot be used in mijlpaal KPI');
        }
        return this.timeSpan ? this.timeSpan : CabKPI.initialTimeSpan(this.getReportingPeriod());
    }

    clearTimeSpan(): boolean {
        if (this.isMijlpaal()) {
            throw new Error('timeSpan cannot be used in mijlpaal KPI');
        }
        const change = !!this.timeSpan;
        if (change) {
            this.timeSpan = undefined;
        }
        return change;
    }

    setTimeSpan(dates: Date[]): boolean {
        if (this.isMijlpaal()) {
            throw new Error('timeSpan cannot be used in mijlpaal KPI');
        }
        const pre = this.getTimeSpan();
        if (dates.length !== 2) {
            throw new Error(`can only set the timespan to a 2 Date array: ${dates}`);
        }
        if (dates[1] < dates[0]) {
            throw new Error(`illegal Date range: ${dates}`);
        }
        const sanDates = CabKPI.sanatizeTimeSpan(dates, this.getReportingPeriod()) ?? dates;
        let change     = false;
        if (diffTimeSpans(pre, sanDates)) {
            change        = true;
            this.timeSpan = sanDates;
        }
        return change;
    }

    getEersteMeetMoment(): Date {
        if (!this.isMijlpaal()) {
            throw new Error('eersteMeetMoment cannot be used in KPI');
        }
        return this.eersteMeetMoment ? this.eersteMeetMoment : CabKPI.initialEersteMeetMoment();
    }

    clearEersteMeetMoment(): boolean {
        if (!this.isMijlpaal()) {
            throw new Error('eersteMeetMoment cannot be used in KPI');
        }
        const change = !!this.eersteMeetMoment;
        if (change) {
            this.eersteMeetMoment = undefined;
        }
        return change;
    }

    setEersteMeetMoment(date: Date): boolean {
        if (!this.isMijlpaal()) {
            throw new Error('eersteMeetMoment cannot be used in KPI');
        }
        const pre     = this.getEersteMeetMoment();
        const sanDate = CabKPI.sanatizeEersteMeetMoment(date) ?? date;
        let change    = false;
        if (diffDates(pre, sanDate)) {
            change                = true;
            this.eersteMeetMoment = sanDate;
        }
        return change;
    }

    getKpiType(): KPITypeEnum {
        switch (this.onderwerp) {
            case OnderwerpEnum.mijlpaal:
            case OnderwerpEnum.financien:
                return KPITypeEnum.input;
            case OnderwerpEnum.personeel:
                return KPITypeEnum.output;
            default:
                return KPITypeEnum.onderliggend;
        }
    }

    listPeriods() {
        const span = this.getTimeSpan();
        return CabPeriod.listPeriodsBetween(span[0], span[1], this.getReportingPeriod());
    }

    getOrgThemeCombis(): { organization: CabOrganization, theme: CabTheme }[] {
        return Object.entries(this.organizations).map(([key, org]) => ({
            organization: org,
            theme       : this.themes[+key],
        }));
    }

    getOrgThemeCombiUuids(): string[][] {
        return Object.entries(this.organizations).map(([key, org]) => [
            uuidOf(org),
            uuidOf(this.themes[+key]),
        ]);
    }

    passes(filter: Filter, limited: boolean = false): boolean {
        const a = super.passes(filter, limited)
            || filter.match(OnderwerpEnum_name(this.onderwerp))
            || filter.match(this.omschrijving)
        ;
        if (limited) {
            return a;
        }
        return a
            || filter.match(VoorspellingsSchaalEnum_name(this.prognoseSchaal))
            || filter.match(renderTerseDate(this.eersteMeetMoment))
            || filter.some(this.organizations, limited)
            || filter.some(this.themes, limited)
            ;
    }
}

@preventNameMangle
export class CabReportItem extends CabObj {
    //###### MIJLPAAL EN KPI ##################################################################################################
    @CabSingleRef
    @Type(() => CabKPI)
    kpi: CabKPI[] = [];

    @CabSingleRef
    @Type(() => CabOrganization)
    organization: CabOrganization[] = [];

    @CabSingleRef
    @Type(() => CabTheme)
    theme: CabTheme[] = [];

    status?: StatusEnum;
    isMijlpaal: boolean = false;

    vecozoId: string         = '';
    uitkeringsbedrag: number = 0;                   // mijlpaal: altijd, kpi: indien kpi.hasUitkeringsbedrag
    reportedValue?: number | MijlpaalEnum;          // WRITABLE BY REPORTER
    prognose?: number | MijlpaalPrognoseEnum;       // WRITABLE BY REPORTER
    opmerking: string        = '';                  // WRITABLE BY REPORTER
    mitigatie: string        = '';                  // WRITABLE BY REPORTER

    //###### ALLEEN MIJLPAAL ##################################################################################################
    meetMoment?: Date;
    onlyPrognose: boolean = false;                  // true indien dit een pre-period (oftewel voor-prognose) is

    //###### ALLEEN KPI #######################################################################################################
    @CabSingleRef
    @Type(() => CabPeriod)
    period: CabPeriod[]                 = [];
    doelwaarde?: number;                            // gezet indien kpi.hasDoelwaarde
    noPrognose: boolean                 = false;                    // dit is een last-period, geen prognose nodig
    kpiStatus?: KpiStatusEnum;                      // WRITABLE BY REPORTER
    kpiStatusPrognose?: KpiStatusPrognoseEnum;      // WRITABLE BY REPORTER
    calculatedValue?: number;
    calculatedKpiStatus?: KpiStatusEnum;
    waardeExpressionHasErrors: boolean  = false;
    waardeExpressionTraces: string      = '';
    gehaaldExpressionHasErrors: boolean = false;
    gehaaldExpressionTraces: string     = '';

    //#########################################################################################################################
    /**
     * Deze methode retourneert de KPI-waarde indien deze beschikbaar is.
     *
     * Als het attribuut `isMijlpaal` waar is, zal deze methode een fout werpen,
     * omdat het concept van een KPI-waarde niet geldig is voor een mijlpaal.
     *
     * @returns {number | undefined} De gerapporteerde waarde (`reportedValue`) als die aanwezig is,
     * of anders de berekende waarde (`calculatedValue`).
     * @throws {Error} Wordt geworpen als geprobeerd wordt de methode aan te roepen voor een mijlpaal.
     */
    public getKpiWaarde(): number | undefined {
        if (this.isMijlpaal) {
            throw new Error('getKpiWaarde() not valid on mijlpaal');
        }
        return this.reportedValue ?? this.calculatedValue;
    }

    /**
     * Geeft de KPI-status terug als deze beschikbaar is.
     *
     * Als het attribuut `isMijlpaal` waar is, zal deze methode een fout werpen,
     * omdat het concept van een KPI-status niet geldig is voor een mijlpaal.
     *
     * @returns {KpiStatusEnum | undefined} De KPI-status (`kpiStatus`) als deze aanwezig is,
     * of anders de berekende KPI-status (`calculatedKpiStatus`).
     * @throws {Error} Wordt geworpen als geprobeerd wordt de methode aan te roepen voor een mijlpaal.
     */
    public getKpiStatus(): KpiStatusEnum | undefined {
        if (this.isMijlpaal) {
            throw new Error('getKpiWaarde() not valid on mijlpaal');
        }
        return this.kpiStatus ?? this.calculatedKpiStatus;
    }

    public showVecozoId(admin: boolean): boolean {
        this.throwIfInconsistent();
        if (admin) {
            return this.kpi[0].hasUitkeringsbedrag ?? false;
        } else {
            return (this.kpi[0].hasUitkeringsbedrag ?? false) && !!this.vecozoId;
        }
    }

    public showUitkeringsbedrag(admin: boolean): boolean {
        this.throwIfInconsistent();
        if (admin) {
            return this.kpi[0].hasUitkeringsbedrag ?? false;
        } else {
            return (this.kpi[0].hasUitkeringsbedrag ?? false) && !!this.uitkeringsbedrag;
        }
    }

    public showReportedValue(): boolean {
        this.throwIfInconsistent();
        if (this.isMijlpaal) {
            return !this.onlyPrognose;
        } else {
            return true;
        }
    }

    public isReportedValueUndefined(): boolean {
        return this.reportedValue === undefined;
    }

    public isReportedValueUnknown(): boolean {
        return this.reportedValue === REPORTED_VALUE_UNKNOWN;
    }

    public setReportedValueAsUnknown() {
        this.reportedValue = REPORTED_VALUE_UNKNOWN;
    }

    public setReportedValueAsUndefined() {
        this.reportedValue = undefined;
    }

    public isPrognoseUndefined(): boolean {
        return this.prognose === undefined;
    }

    public isPrognoseUnknown(): boolean {
        return this.prognose === REPORTED_VALUE_UNKNOWN;
    }

    public setPrognoseAsUnknown() {
        this.prognose = REPORTED_VALUE_UNKNOWN;
    }

    public setPrognoseAsUndefined() {
        this.prognose = undefined;
    }

    public showPrognose(): boolean {
        this.throwIfInconsistent();
        if (this.isMijlpaal) {
            return this.kpi[0].prognoseSchaal !== PrognoseSchaalEnum.none && (this.onlyPrognose || this.reportedValue === MijlpaalEnum.gemist || this.reportedValue === MijlpaalEnum.onbekend);
        } else {
            if (!this.noPrognose && this.kpi[0].prognoseSchaal !== PrognoseSchaalEnum.none) {
                return true;
            } else {
                return this.kpiStatus === KpiStatusEnum.gemist || this.kpiStatus === KpiStatusEnum.onbekend;
            }
        }
    }

    public showMitigatie(): boolean {
        this.throwIfInconsistent();
        if (this.isMijlpaal) {
            return (this.reportedValue === MijlpaalEnum.gemist || this.reportedValue === MijlpaalEnum.onbekend)
                || (this.onlyPrognose && (this.prognose === MijlpaalPrognoseEnum.wordtGemist || this.prognose === MijlpaalPrognoseEnum.kansOpMissen));
        } else {
            return (this.showKpiStatus() && (this.kpiStatus === KpiStatusEnum.gemist || this.kpiStatus === KpiStatusEnum.onbekend))
                || (!this.kpi[0].isCalculatedValue && this.isReportedValueUnknown());
        }
    }

    public showDoelwaarde(admin: boolean): boolean {
        this.throwIfInconsistent();
        if (this.isMijlpaal) {
            return false;
        } else {
            if (admin) {
                return this.kpi[0].hasDoelwaarde;
            } else {
                return this.kpi[0].hasDoelwaarde && this.doelwaarde !== undefined;
            }
        }
    }

    public showKpiStatus(): boolean {
        this.throwIfInconsistent();
        if (this.isMijlpaal) {
            return false;
        } else {
            return this.kpi[0].askKpiStatus || !!this.kpi[0].gehaaldExpression;
        }
    }

    public showCalculatedKpiStatus(): boolean {
        this.throwIfInconsistent();
        if (this.isMijlpaal) {
            return false;
        } else {
            return !!this.kpi[0].gehaaldExpression;
        }
    }

    public showKpiStatusPrognose(): boolean {
        this.throwIfInconsistent();
        if (this.isMijlpaal) {
            return false;
        } else {
            return this.kpi[0].askKpiStatus && (this.kpiStatus === KpiStatusEnum.gemist || this.kpiStatus === KpiStatusEnum.onbekend);
        }
    }

    isSubmittable() {
        if (this.isInconsistent() || (this.status !== StatusEnum.open && this.status !== StatusEnum.overdue)) {
            return false;
        }
        if (!this.kpi[0].isCalculatedValue && this.showReportedValue() && this.isReportedValueUndefined()) {
            return false;
        }
        if (this.showPrognose() && this.prognose === undefined) {
            return false;
        }
        if (this.showMitigatie() && !this.mitigatie) {
            return false;
        }
        if (this.showDoelwaarde(false) && this.doelwaarde == undefined) {
            return false;
        }
        if (!this.kpi[0].gehaaldExpression && this.showKpiStatus() && this.kpiStatus == undefined) {
            return false;
        }
        // noinspection RedundantIfStatementJS
        if (this.showKpiStatusPrognose() && this.kpiStatusPrognose == undefined) {
            return false;
        }
        return true;
    }

    //#########################################################################################################################
    nextMeetMoment() {
        if (!this.isMijlpaal) {
            throw new Error('can not calc next meetmoment for a non-mijlpaal');
        }
        return nextQuarter(this.meetMoment!);
    }

    prevMeetMoment() {
        if (!this.isMijlpaal) {
            throw new Error('can not calc prev meetmoment for a non-mijlpaal');
        }
        return prevQuarter(this.meetMoment!);
    }

    compare<T extends CabObj>(that: T): number {
        if (!(that instanceof CabReportItem)) {
            throw new Error(`compare() can only compare CabReportItem's, refactor ${that.constructor.name}`);
        }
        const nameCompare = this.kpi[0].name.localeCompare(that.kpi[0].name);
        if (nameCompare !== 0) {
            return nameCompare;
        }
        const thisMP = this.isMijlpaal;
        const thatMP = that.isMijlpaal;
        if (thisMP != thatMP) {
            return thisMP ? -1 : +1;
        }
        if (!thisMP) {
            const cmpDate = this.period[0].compare(that.period[0]);
            if (cmpDate !== 0) {
                return cmpDate;
            }
        } else {
            const thisD = this.meetMoment!;
            const thatD = that.meetMoment!;
            const comp  = thisD.getTime() - thatD.getTime();
            if (comp !== 0) {
                return comp;
            }
        }
        return this.kpi[0].name.localeCompare(that.kpi[0].name);
    }

    public throwIfInconsistent() {
        const error = this.isInconsistent();
        if (error) {
            console.log('inconsistent CabReportItem', this);
            throw error;
        }
    }

    public isInconsistent() {
        if (!this.kpi || this.kpi.length != 1 || !this.kpi[0].uuid) {
            return new Error('inconsistent CabReportItem: no valid kpi');
        }
        if (!this.organization || this.organization.length != 1 || !this.organization[0].uuid) {
            return new Error('inconsistent CabReportItem: no valid organization');
        }
        if (!this.theme || this.theme.length != 1 || !this.theme[0].uuid) {
            return new Error('inconsistent CabReportItem: no valid theme');
        }
        if (isInSync(this.kpi[0]) && !this.kpi[0].onderwerp) {
            return new Error('inconsistent CabReportItem: insync kpi is not valid');
        }
        if (this.isMijlpaal) {
            if (this.period && 0 < this.period.length) {
                return new Error('inconsistent CabReportItem: mijlpaal requires no period');
            }
        } else {
            if (this.meetMoment) {
                return new Error('inconsistent CabReportItem: not mijlpaal requires no meetMoment');
            }
        }
        return undefined;
    }

    passes(filter: Filter, limited: boolean = false): boolean {
        const both = super.passes(filter, limited)
            || filter.match(StatusEnum_name(this.status))
            || filter.match(this.vecozoId)
            || filter.match(this.uitkeringsbedrag)
            || filter.match(this.opmerking)
            || filter.match(this.mitigatie)
            || filter.some(this.kpi, true)
            || filter.some(this.organization, limited)
            || filter.some(this.theme, limited)
        ;
        let spec;
        if (this.isMijlpaal) {
            spec = filter.match(MijlpaalEnum_name(this.reportedValue))
                || filter.match(MijlpaalPrognoseEnum_name(this.prognose))
                || filter.match(renderTerseDate(this.meetMoment))
            ;
        } else {
            spec = filter.match(this.doelwaarde)
                || filter.match(KpiStatusEnum_name(this.kpiStatus))
                || filter.match(KpiStatusPrognoseEnum_name(this.kpiStatusPrognose))
                || filter.match(this.calculatedValue)
                || filter.match(KpiStatusEnum_name(this.calculatedKpiStatus))
            ;
        }
        return both || spec;
    }
}

@preventNameMangle
export class CabPeriod extends CabObj {
    year: number     = 0;
    unit: PeriodEnum = PeriodEnum.Q;
    num: number      = 0; // 0-based!

    static ofString(qh: string): CabPeriod {
        const regex = /^(\d{4})([QH])(\d)$/;
        const match = qh.replace(/[^0-9QH]/, '').match(regex);
        if (!match) {
            throw new Error(`Invalid qh format: ${qh}`);
        }
        const period = new CabPeriod();
        period.year  = parseInt(match[1], 10);
        period.unit  = match[2] === 'Q' ? PeriodEnum.Q : PeriodEnum.H;
        period.num   = parseInt(match[3], 10) - 1; // Adjust to 0-based index
        period.validate();
        return period;
    }

    static of(date: Date, unit: PeriodEnum): CabPeriod {
        const p: CabPeriod = new CabPeriod();
        p.year             = date.getUTCFullYear();
        p.unit             = unit;
        p.num              = Math.floor(date.getUTCMonth() / p.monthsPerUnit());
        return p;
    }

    qPeriod(): CabPeriod {
        return this.unit === PeriodEnum.Q ? this : CabPeriod.of(this.beginDate(), PeriodEnum.Q).nextPeriod();
    }

    beginDate(): Date {
        return new Date(Date.UTC(this.year, this.num * this.monthsPerUnit()));
    }

    endDate(): Date {
        return new Date(Date.UTC(this.year, (this.num + 1) * this.monthsPerUnit()));
    }

    static listPeriodsBetween(d1: Date, d2: Date, unit: PeriodEnum): CabPeriod[] {
        const start: Date          = CabPeriod.of(d1, unit).beginDate();
        const end: Date            = CabPeriod.of(d2, unit).beginDate();
        const periods: CabPeriod[] = [];
        for (let date: Date = new Date(start); date < end; date.setUTCMonth(date.getUTCMonth() + CabPeriod.monthsPer(unit))) {
            periods.push(CabPeriod.of(new Date(date), unit));
        }
        return periods;
    }

    static monthsPer(unit: PeriodEnum) {
        return unit === PeriodEnum.Q ? MONTHS_PER_QUARTER : MONTHS_PER_HALF;
    }

    static unitsPerYear(unit: PeriodEnum) {
        return unit === PeriodEnum.Q ? QUARTERS_PER_YEAR : HALVES_PER_YEAR;
    }

    /*private*/
    monthsPerUnit() {
        return CabPeriod.monthsPer(this.unit);
    }

    /*private*/
    unitsPerYear() {
        return CabPeriod.unitsPerYear(this.unit);
    }

    nextPeriod(): CabPeriod {
        const p = new CabPeriod();
        p.year  = this.year;
        p.unit  = this.unit;
        p.num   = this.num + 1;
        if (p.num === this.unitsPerYear()) {
            p.num -= this.unitsPerYear();
            p.year++;
        }
        return p;
    }

    previousPeriod(): CabPeriod {
        const p = new CabPeriod();
        p.year  = this.year;
        p.unit  = this.unit;
        p.num   = this.num - 1;
        return p.validate();
    }

    validate(): CabPeriod {
        this.year += Math.floor(this.num / QUARTERS_PER_YEAR);
        this.num = ((this.num % QUARTERS_PER_YEAR) + QUARTERS_PER_YEAR) % QUARTERS_PER_YEAR; //extra addition is necessary because -1 % 4 = -1
        return this;
    }

    compare<T extends CabObj>(that: T): number {
        if (!(that instanceof CabPeriod)) {
            throw new Error(`compare() can only compare CabPeriod's, refactor ${that.constructor.name}`);
        }
        if (this.year != that.year) {
            return this.year - that.year;
        } else if (this.unit != that.unit) {
            // compare H vs Q periods: compare if the next period starts on the same month:
            return (this.num + 1) * this.monthsPerUnit() - (that.num + 1) * that.monthsPerUnit();
        } else {
            return this.num - that.num;
        }
    }

    public monthsInPeriod(): number[] {
        const n      = 1 + (this.num) * this.monthsPerUnit();
        const m      = (this.num + 1) * this.monthsPerUnit();
        const length = m - n + 1;
        return Array.from({length}, (_, i) => n + i);
    }

    toString(): string {
        return `${this.year}-${PeriodEnum_char(this.unit)}${this.num + 1}`;
    }
}

@preventNameMangle
export class CabWaitingTime extends CabObj {
    wachttijdApiType?: WachttijdApiTypeEnum;
    reportedOn: Date     = new Date();
    kvkNumber: string    = '';
    treatmentKey: string = '';
    locationKey: string  = '';
    treekNormCategory?: TreekNormCategory;

    careProvider: string   = '';
    locationName: string   = '';
    treatmentName?: string = '';
    unavailabilityReason?: UnavailabilityReasonEnum;
    waitingTimeInDagen?: number;

    public getUniqueKey() {
        return [
            this.wachttijdApiType,
            this.kvkNumber,
            this.reportedOn.getMonth(),
            this.reportedOn.getFullYear(),
            this.treatmentKey,
            this.locationKey,
            this.treekNormCategory,
            this.reportedOn.toISOString(),
        ]
            .map(x => String(x))
            .join('|');
    }

    public equal(that: CabWaitingTime) {
        if ((typeof this.reportedOn.getMonth) !== 'function') {
            console.error('PROBLEM, CabWaitingTime.reportedOn is geen Date');
        }
        return this.wachttijdApiType === that.wachttijdApiType
            && this.kvkNumber === that.kvkNumber
            && this.reportedOn.getMonth() === that.reportedOn.getMonth()
            && this.reportedOn.getFullYear() === that.reportedOn.getFullYear()
            && this.treatmentKey === that.treatmentKey
            && this.locationKey === that.locationKey
            && this.treekNormCategory === that.treekNormCategory
            && this.reportedOn.toISOString() === that.reportedOn.toISOString()
            ;
    }

    public completelyTheSame(that: CabWaitingTime) {
        return this.equal(that)
            && this.careProvider === that.careProvider
            && this.locationName === that.locationName
            && this.unavailabilityReason === that.unavailabilityReason
            && this.waitingTimeInDagen === that.waitingTimeInDagen
            && this.treatmentName === that.treatmentName
            ;
    }
}

registerClass(CabUser);
registerClass(CabTheme);
registerClass(CabOrganization);
registerClass(CabKPI);
registerClass(CabReportItem);
registerClass(CabPeriod);
registerClass(CabWaitingTime);

export const ROLE_ADMIN: string        = 'admin';
export const ROLE_REPORTER: string     = 'reporter';
export const PERMISSION_ADMIN: string  = 'admin';
export const PERMISSION_REPORT: string = 'report';

export function generateDateId(): string {
    const now: Date      = new Date();
    const year: number   = now.getUTCFullYear();
    const month: string  = String(now.getUTCMonth() + 1).padStart(2, '0');
    const day: string    = String(now.getUTCDate()).padStart(2, '0');
    const hour: string   = String(now.getUTCHours()).padStart(2, '0');
    const minute: string = String(now.getUTCMinutes()).padStart(2, '0');
    const second: string = String(now.getUTCSeconds()).padStart(2, '0');

    return `${year}${month}${day}_${hour}${minute}${second}`;
}

export function nextQuarter(mm: Date) {
    const y = mm.getUTCFullYear();
    const m = Math.floor((mm.getUTCMonth()) / MONTHS_PER_QUARTER) * MONTHS_PER_QUARTER;
    const x = new Date(Date.UTC(y, m, 1));
    x.setUTCMonth(x.getUTCMonth() + MONTHS_PER_QUARTER);
    return x;
}

export function prevQuarter(mm: Date) {
    const y = mm.getUTCFullYear();
    const m = Math.floor((mm.getUTCMonth()) / MONTHS_PER_QUARTER) * MONTHS_PER_QUARTER;
    const x = new Date(Date.UTC(y, m, 1));
    x.setMonth(x.getMonth() - MONTHS_PER_QUARTER);
    return x;
}

