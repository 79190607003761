<script setup lang="ts">
import {StatusEnum, StatusEnum_heavyColor, StatusEnum_name} from 'common';
import {ref, toRef, watch} from 'vue';

const emit = defineEmits(
    ['update:statusSet'],
);

const props = defineProps({
  multi    : Boolean,
  statusSet: {
    type    : Array<StatusEnum>,
    required: true,
  },
});
const refStatusSet = toRef(props, 'statusSet')
watch(refStatusSet, () => {
  statusSet.value = refStatusSet.value
})

const statusSet = ref<StatusEnum[]>([...props.statusSet]);

function tileClass(e: StatusEnum) {
  const on    = statusSet.value.includes(e);
  const color = StatusEnum_heavyColor(e);

  const onoff = on ? `bg-${color}` : `bg-gray-100`;
  return `w-24 py-1 cursor-pointer ${onoff} text-center text-black hover:font-extrabold border-4 border-${color} rounded-lg`;
}

function mainClass() {
  return `flex flex-${(props.multi ? 'row' : 'col')} text-xs py-2 gap-0.5 bg-200`;
}

function onClick(e: StatusEnum) {
  if (statusSet.value.includes(e)) {
    if (props.multi) {
      statusSet.value.splice(statusSet.value.indexOf(e), 1);
    }
  } else {
    if (!props.multi) {
      statusSet.value = [];
    }
    statusSet.value.push(e);
  }
  emit('update:statusSet', [...statusSet.value]);
}

</script>

<template>

  <div :class="mainClass()" class="">

    <div :class="tileClass(StatusEnum.overdue)"
         @click="onClick(StatusEnum.overdue)"
    >
      {{ StatusEnum_name(StatusEnum.overdue) }}
    </div>

    <div :class="tileClass(StatusEnum.open)"
         @click="onClick(StatusEnum.open)"
    >
      {{ StatusEnum_name(StatusEnum.open) }}
    </div>

    <div :class="tileClass(StatusEnum.future)"
         @click="onClick(StatusEnum.future)"
    >
      {{ StatusEnum_name(StatusEnum.future) }}
    </div>

    <div :class="tileClass(StatusEnum.submitted)"
         @click="onClick(StatusEnum.submitted)"
    >
      {{ StatusEnum_name(StatusEnum.submitted) }}
    </div>

    <div :class="tileClass(StatusEnum.accepted)"
         @click="onClick(StatusEnum.accepted)"
    >
      {{ StatusEnum_name(StatusEnum.accepted) }}
    </div>

    <div :class="tileClass(StatusEnum.rejected)"
         @click="onClick(StatusEnum.rejected)"
    >
      {{ StatusEnum_name(StatusEnum.rejected) }}
    </div>

  </div>

</template>
