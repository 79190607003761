<script setup lang="ts">
import {onUnmounted, type PropType, ref, watch} from 'vue';
import {
    CabKPI,
    type CabReportItem,
    enum_values,
    KpiStatusEnum,
    KpiStatusEnum_name,
    KpiStatusPrognoseEnum,
    KpiStatusPrognoseEnum_name,
    MijlpaalEnum,
    MijlpaalEnum_name,
    MijlpaalPrognoseEnum,
    MijlpaalPrognoseEnum_name,
    StatusEnum,
    StatusEnum_color,
    WaardeTypeEnum,
} from 'common';
import {updateReportItem} from '@/base/callBackend';
import 'element-plus/dist/index.css';
import {ElInput, ElOption, ElSelect} from 'element-plus';
import WaardeInvul from '@/views/general/WaardeInvul.vue';
import Waarde from '@/views/general/Waarde.vue';

//=================================================================================================
const emit  = defineEmits(['refreshReports']);
const props = defineProps({
    reportitem   : {
        type    : Object as PropType<CabReportItem>,
        required: true,
    },
    flagOmissions: {
        type    : Boolean,
        required: true,
    },
});

const kpi                  = ref<CabKPI>(props.reportitem.kpi[0]);
const theValue             = ref<number | MijlpaalEnum | undefined>(props.reportitem.reportedValue);
const theKpiStatus         = ref<KpiStatusEnum | undefined>(props.reportitem.kpiStatus);
const thePrognose          = ref<number | MijlpaalPrognoseEnum | undefined>(props.reportitem.prognose);
const theKpiStatusPrognose = ref<KpiStatusPrognoseEnum | undefined>(props.reportitem.kpiStatusPrognose);
const statusChanged        = ref<boolean>(false);

//=================================================================================================
watch(theValue, async (v) => {
    await reportedValueChanged(v);
    if (kpi.value.isMijlpaal()) {
        statusChanged.value = true;
    }
});
watch(theKpiStatus, async (v) => {
    await kpiStatusValueChanged(v);
});
watch(thePrognose, async (v) => {
    await prognoseChanged(v);
});
watch(theKpiStatusPrognose, async (v) => {
    await kpiStatusPrognoseChanged(v);
});

onUnmounted(() => {
    if (statusChanged.value) {
        emit('refreshReports');
    }
});

//=================================================================================================
async function reportedValueChanged(v: number | MijlpaalEnum | undefined) {
    if (v !== theValue.value || v !== props.reportitem.reportedValue) {
        theValue.value                 = v;
        props.reportitem.reportedValue = v;
        await onChange();
    }
}

async function kpiStatusValueChanged(v: KpiStatusEnum | undefined) {
    if (v !== theKpiStatus.value || v !== props.reportitem.kpiStatus) {
        theKpiStatus.value         = v;
        props.reportitem.kpiStatus = v;
        await onChange();
    }
}

async function prognoseChanged(v: number | MijlpaalPrognoseEnum | undefined) {
    if (v !== thePrognose.value || v !== props.reportitem.prognose) {
        thePrognose.value         = v;
        props.reportitem.prognose = v;
        await onChange();
    }
}

async function kpiStatusPrognoseChanged(v: KpiStatusPrognoseEnum | undefined) {
    if (v !== theKpiStatusPrognose.value || v !== props.reportitem.kpiStatusPrognose) {
        theKpiStatusPrognose.value         = v;
        props.reportitem.kpiStatusPrognose = v;
        await onChange();
    }
}

function keyupOnTheValue(event: any) {
    if (event.key === 'Backspace' || event.key === 'Delete') {
        theValue.value = undefined;
        event.originalTarget.blur();
    }
}

function keyupOnTheKpiStatus(event: any) {
    if (event.key === 'Backspace' || event.key === 'Delete') {
        theKpiStatus.value = undefined;
        event.originalTarget.blur();
    }
}

function keyupOnThePrognose(event: any) {
    if (event.key === 'Backspace' || event.key === 'Delete') {
        thePrognose.value = undefined;
        event.originalTarget.blur();
    }
}

function keyupOnTheKpiStatusPrognose(event: any) {
    if (event.key === 'Backspace' || event.key === 'Delete') {
        theKpiStatusPrognose.value = undefined;
        event.originalTarget.blur();
    }
}

function isWaardeCalculated(): boolean {
    return !isMijlpaal() && kpi.value.isCalculatedValue;
}

function isStatusCalculated(): boolean {
    return !isMijlpaal() && !!kpi.value.gehaaldExpression;
}

async function onChange() {
    const newItem = await updateReportItem(props.reportitem);
    if (newItem) {
        props.reportitem.calculatedKpiStatus = newItem.calculatedKpiStatus;
    }
}

//=================================================================================================
function open_or_overdue() {
    return props.reportitem.status === StatusEnum.open || props.reportitem.status === StatusEnum.overdue;
}

function isMijlpaal() {
    return props.reportitem?.isMijlpaal;
}

//=================================================================================================
function dynColor() {
    const status = props.reportitem.status;
    const text   = status === StatusEnum.submitted || status === StatusEnum.accepted ? 'text-gray-500' : '';
    return 'bg-' + StatusEnum_color(status) + ' ' + text;
}

function dynClassForLabelColumn() {
    return 'shrink-0 w-36 mr-4 py-1 text-right';
}

function omissionColor(v: number | MijlpaalEnum | string | undefined) {
    return props.flagOmissions && (v === undefined || v === '') ? 'outline outline-3 outline-red-500' : '';
}

//=================================================================================================
</script>

<template>
    <div class="flex flex-col justify-start items-stretch pr-4" :class="dynColor()">

        <div class="flex flex-col space-y-1 ml-10 my-8">
            <!--+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++-->
            <div v-if="isMijlpaal() && reportitem.showReportedValue()"
                 class="flex flex-row justify-start items-center py-1">
                <div :class="dynClassForLabelColumn()" v-if="open_or_overdue()">
                    ik rapporteer
                </div>
                <div :class="dynClassForLabelColumn()" v-else>
                    ik rapporteerde
                </div>

                <div class="font-bold w-72">
                    <el-select
                        :class="omissionColor(theValue)"
                        v-model="theValue"
                        placeholder="kies een optie"
                        style="min-width: 210px"
                        :disabled="!open_or_overdue()"
                        @keyup="keyupOnTheValue($event)"
                    >
                        <el-option
                            v-for="item in enum_values(MijlpaalEnum)"
                            :key="item"
                            :label="MijlpaalEnum_name(item)"
                            :value="item"
                        />
                    </el-select>
                </div>
            </div>
            <!--+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++-->
            <div v-if="!isMijlpaal() && (reportitem.showReportedValue() || reportitem.showKpiStatus())"
                 class="flex flex-row justify-start items-center py-1">

                <template v-if="reportitem.showReportedValue()">
                    <div :class="dynClassForLabelColumn()" v-if="isWaardeCalculated()">
                        berekend als
                    </div>
                    <div :class="dynClassForLabelColumn()" v-else-if="open_or_overdue()">
                        ik rapporteer
                    </div>
                    <div :class="dynClassForLabelColumn()" v-else>
                        ik rapporteerde
                    </div>

                    <WaardeInvul
                        v-model:waarde="theValue"
                        :waarde-type="kpi.waardeType"
                        class="font-bold"
                        :class="isWaardeCalculated()?'':omissionColor(theValue)"
                        mag-onbekend
                        :disabled="isWaardeCalculated()"
                    />
                </template>

                <template v-if="reportitem.showKpiStatus() && !isStatusCalculated()">
                    <div :class="dynClassForLabelColumn()">
                        met status
                    </div>
                    <div class="font-bold w-72">
                        <el-select
                            :class="omissionColor(theKpiStatus)"
                            v-model="theKpiStatus"
                            placeholder="kies een optie"
                            style="min-width: 210px"
                            :disabled="!open_or_overdue()"
                            @keyup="keyupOnTheKpiStatus($event)"
                        >
                            <el-option
                                v-for="item in enum_values(KpiStatusEnum)"
                                :key="item"
                                :label="KpiStatusEnum_name(item)"
                                :value="item"
                            />
                        </el-select>
                    </div>
                </template>

                <template v-if="reportitem.showCalculatedKpiStatus()">
                    <div :class="dynClassForLabelColumn()">
                        berekende status
                    </div>
                    <div class="font-bold">
                        {{ KpiStatusEnum_name(reportitem.calculatedKpiStatus) }}
                    </div>
                </template>

            </div>
            <!--+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++-->
            <div class="flex flex-row justify-start items-center py-1"
                 v-if="reportitem.showPrognose() || reportitem.showKpiStatusPrognose()">
                <template v-if="reportitem.showPrognose()">
                    <div v-if="isMijlpaal()"
                         class="flex flex-row justify-start items-start">
                        <div :class="dynClassForLabelColumn()">
                            prognose
                        </div>
                        <div class="font-bold w-72">
                            <el-select
                                :class="omissionColor(thePrognose)"
                                v-model="thePrognose"
                                placeholder="kies een optie"
                                style="min-width: 270px"
                                :disabled="!open_or_overdue()"
                                @keyup="keyupOnThePrognose($event)"
                            >
                                <el-option
                                    v-for="item in enum_values(MijlpaalPrognoseEnum)"
                                    :key="item"
                                    :label="MijlpaalPrognoseEnum_name(item)"
                                    :value="item"
                                />
                            </el-select>
                        </div>
                    </div>

                    <div v-if="!isMijlpaal()"
                         class="flex flex-row justify-start items-baseline">
                        <div :class="dynClassForLabelColumn()">
                            prognose
                        </div>
                        <WaardeInvul
                            v-model:waarde="thePrognose"
                            :waarde-type="kpi.waardeType"
                            class="font-bold"
                            :class="omissionColor(thePrognose)"
                            mag-onbekend
                        />
                    </div>
                </template>

                <template v-if="reportitem.showKpiStatusPrognose()">
                    <div :class="dynClassForLabelColumn()">
                        met status
                    </div>

                    <div class="font-bold w-72">
                        <el-select
                            :class="omissionColor(theKpiStatusPrognose)"
                            v-model="theKpiStatusPrognose"
                            placeholder="kies een optie"
                            style="min-width: 270px"
                            :disabled="!open_or_overdue()"
                            @keyup="keyupOnTheKpiStatusPrognose($event)"
                        >
                            <el-option
                                v-for="item in enum_values(KpiStatusPrognoseEnum)"
                                :key="item"
                                :label="KpiStatusPrognoseEnum_name(item)"
                                :value="item"
                            />
                        </el-select>
                    </div>
                </template>

            </div>
            <!--+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++-->
            <div class="flex flex-row justify-start items-center py-1"
                 v-if="reportitem.showMitigatie()">
                <div :class="dynClassForLabelColumn()">
                    mitigatie
                </div>
                <div class="font-bold w-full">
                    <el-input
                        :class="omissionColor(reportitem.mitigatie)"
                        v-model="reportitem.mitigatie"
                        @input="onChange()"
                        type="textarea"
                        :autosize="{ minRows: 1, maxRows: 5 }"
                        :disabled="!open_or_overdue()"
                    />
                </div>
            </div>
            <!--+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++-->
            <div class="flex flex-row justify-start items-center py-1">
                <div :class="dynClassForLabelColumn()">
                    opmerkingen
                </div>
                <div class="font-bold w-full">
                    <el-input
                        v-model="reportitem.opmerking"
                        @input="onChange()"
                        type="textarea"
                        :autosize="{ minRows: 1, maxRows: 5 }"
                        :disabled="!open_or_overdue()"
                    />
                </div>
            </div>
            <!--+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++-->
            <div class="flex flex-row justify-start items-center py-1"
                 v-if="!!kpi.omschrijving">
                <div :class="dynClassForLabelColumn()">
                    omschrijving
                </div>
                <div class="bg-white/50 px-2 w-full">
                    {{ kpi.omschrijving }}
                </div>
            </div>
            <!--+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++-->
            <div class="flex flex-row justify-start items-center py-1"
                 v-if="reportitem.showDoelwaarde(false) || (isStatusCalculated() && kpi.hasDoelwaarde)">
                <div :class="dynClassForLabelColumn()">
                    doelwaarde
                </div>
                <div class="bg-white/50 px-2">
                    <Waarde
                        :waarde="reportitem.doelwaarde"
                        :waarde-type="kpi.waardeType"/>
                </div>
            </div>
            <!--+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++-->
            <div class="flex flex-row justify-start items-center py-1"
                 v-if="reportitem.showUitkeringsbedrag(false)">
                <div :class="dynClassForLabelColumn()">
                    uitkeringsbedrag
                </div>
                <div class="bg-white/50 px-2">
                    <Waarde
                        :waarde="reportitem.uitkeringsbedrag"
                        :waarde-type="WaardeTypeEnum.bedrag"
                    />
                </div>
            </div>
            <!--+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++-->
            <div class="flex flex-row justify-start items-center py-1"
                 v-if="reportitem.showVecozoId(false)">
                <div :class="dynClassForLabelColumn()">
                    vecozo id
                </div>
                <div class="bg-white/50 px-2">
                    {{ reportitem.vecozoId }}
                </div>
            </div>
            <!--+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++-->
        </div>

    </div>
</template>

<style></style>
