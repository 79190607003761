<template>
  <div
      class="flex justify-center items-center w-7 h-7 border-0 text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-300 rounded-lg px-1 py-0 mx-1"
  >
    <svg
        viewBox="0 0 24 24"
        class="w-5 h-5"
        fill="none"
        stroke-width="2"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 12H19"/>
      <path d="M12 5L12 19"/>
    </svg>
  </div>
</template>
