<script setup lang="ts">
import {onMounted, ref, watch} from 'vue';
import {listAdminItems, listKpis, listOrganizations} from '@/base/callBackend';
import {CabKPI, CabOrganization, CabReportItem, CabTheme, renderRI, StatusEnum, uuidOf} from 'common';
import {ElButton, ElOption, ElSelect, ElSkeleton, ElSkeletonItem} from 'element-plus';
import AdminReportKpi from '@/views/admin/AdminReportKpi.vue';
import StatusSelector from '@/views/general/StatusSelector.vue';
import {VList} from 'virtua/vue';
import {EVENT_BUS} from '@/base/EVENT_BUS.js';
import Marker from '@/views/general/Marker.vue';

// still experimenting with a vlist approach.
// it reduces delays but also reduces search by text.
// the approach can be switched by the following flag:
const USE_VLIST: boolean = false;

const ADMIN_STATE: string = 'ADMIN_STATE';
type AdminState = {
    organizationUuid: string,
    themeMap: { [key: string]: string },
    statusList: StatusEnum[],
}

function initAdminState() {
    return {
        organizationUuid: '',
        themeMap        : {},
        statusList      : [StatusEnum.open],
    };
}

const initAdmin = JSON.parse(localStorage.getItem(ADMIN_STATE) || JSON.stringify(initAdminState()));

const allOrganizations         = ref<CabOrganization[]>([]);
const state                    = ref<AdminState>(initAdminState());
const allThemes                = ref<CabTheme[]>([]);
const loadingMain              = ref(true);
const selectedOrganizationUuid = ref<string>('');
const selectedThemeUuid        = ref<string>('');
const selectedOrganization     = ref<CabOrganization>();
const selectedTheme            = ref<CabTheme>();
const reloadTrigger            = ref<number>(0);
const loadingSub               = ref(true);
const allKpis                  = ref<CabKPI[]>([]);
const allReportItems           = ref<CabReportItem[]>();
const statusSet                = ref<StatusEnum[]>(initAdmin.statusList);

onMounted(async () => {
    allOrganizations.value         = await listOrganizations();
    state.value                    = initAdmin;
    //
    selectedOrganizationUuid.value = state.value.organizationUuid || '';
    selectedOrganization.value     = getOrganization(selectedOrganizationUuid.value);
    selectedOrganizationUuid.value = selectedOrganization.value.uuid || '';
    //
    allThemes.value                = selectedOrganization.value.themes || [];
    //
    selectedThemeUuid.value        = state.value.themeMap[selectedOrganizationUuid.value] || '';
    selectedTheme.value            = getTheme(selectedThemeUuid.value);
    selectedThemeUuid.value        = selectedTheme.value.uuid || '';
    //
    loadingMain.value              = false;
    reloadTrigger.value++;
});

watch(selectedOrganizationUuid, () => {
    state.value.organizationUuid = selectedOrganizationUuid.value;
    localStorage.setItem(ADMIN_STATE, JSON.stringify(state.value));
    //
    selectedOrganization.value     = getOrganization(selectedOrganizationUuid.value);
    selectedOrganizationUuid.value = selectedOrganization.value.uuid || '';
    //
    allThemes.value                = selectedOrganization.value.themes || [];
    //
    selectedThemeUuid.value        = state.value.themeMap[selectedOrganizationUuid.value] || '';
    selectedTheme.value            = getTheme(selectedThemeUuid.value);
    selectedThemeUuid.value        = selectedTheme.value.uuid || '';
    //
    reloadTrigger.value++;
});
watch(selectedThemeUuid, () => {
    state.value.themeMap[selectedOrganizationUuid.value] = selectedThemeUuid.value;
    localStorage.setItem(ADMIN_STATE, JSON.stringify(state.value));
    //
    selectedTheme.value     = getTheme(selectedThemeUuid.value);
    selectedThemeUuid.value = selectedTheme.value.uuid || '';
    //
    reloadTrigger.value++;
});
watch(statusSet, () => {
    state.value.statusList = statusSet.value;
    localStorage.setItem(ADMIN_STATE, JSON.stringify(state.value));
    //
    reloadTrigger.value++;
});
watch(reloadTrigger, async () => {
    loadingSub.value = true;
    allKpis.value    = await listKpis({
        organizations: selectedOrganizationUuid.value,
        themes       : selectedThemeUuid.value,
    });
    allKpis.value    = allKpis.value.filter(k => !!k.getOrgThemeCombiUuids().find(combi => combi[0] === selectedOrganizationUuid.value && combi[1] === selectedThemeUuid.value));
    const all        = await listAdminItems({
        kpi         : allKpis.value.map(k => k.uuid),
        organization: selectedOrganizationUuid.value,
        theme       : selectedThemeUuid.value,
    });
    for (const ri of all) {
        if (ri.status !== StatusEnum.future) {
            if (ri.kpi[0].waardeExpression && !ri.waardeExpressionTraces) {
                console.log(`TOMTOMTOM waardeExpression  maar geen trace ${renderRI(ri)}`);
            }
            if (ri.kpi[0].gehaaldExpression && !ri.gehaaldExpressionTraces) {
                console.log(`TOMTOMTOM gehaaldExpression maar geen trace ${renderRI(ri)}`);
            }
        }
    }
    allReportItems.value = all.filter(ri => statusSet.value.includes(ri.status!));
    loadingSub.value     = false;
});


function getOrganization(uuid: string) {
    return allOrganizations.value.find(o => uuidOf(o) === uuid) || allOrganizations.value[0];
}

function getTheme(uuid: string) {
    return allThemes.value.find(o => uuidOf(o) === uuid) || allThemes.value[0];
}

function allReportItemsFor(kpi: CabKPI): CabReportItem[] {
    return allReportItems.value?.filter(ri => ri.kpi[0].uuid === kpi.uuid) ?? [];
}

function goToTop() {
    document.getElementById('app')?.scrollTo({
        top     : 0,
        behavior: 'smooth',
    });
}

function sendEvent(msg: 'red' | 'green') {
    EVENT_BUS[msg] = !EVENT_BUS[msg];
}
</script>

<template>
    <el-button size="large"
               @click="goToTop"
               class="fixed bottom-3 left-3 w-14 h-14 rounded text-4xl"
    >🔝
    </el-button>

    <div class="flex flex-col flex-1 mx-28 my-5">
        <div class="flex flex-row flex-1 items-center">

            <div class="flex flex-row items-center my-3">
                <div class="text-base">
                    Rapportages voor
                </div>
                <div v-if="loadingMain">
                    <el-skeleton animated>
                        <template #template>
                            <el-skeleton-item class="w-52 h-8 mx-1"/>
                        </template>
                    </el-skeleton>
                </div>
                <el-select v-else v-model="selectedOrganizationUuid" class="w-52 mx-1">
                    <el-option
                        v-for="org in allOrganizations"
                        :key="uuidOf(org)"
                        :value="uuidOf(org)"
                        :label="org.name"
                    />
                </el-select>
                <div class="text-base mx-1">
                    met thema
                </div>
                <div v-if="loadingMain">
                    <el-skeleton animated>
                        <template #template>
                            <el-skeleton-item class="w-52 h-8 mx-1"/>
                        </template>
                    </el-skeleton>
                </div>
                <el-select v-else v-model="selectedThemeUuid" class="w-52 mx-1">
                    <el-option
                        v-for="theme in allThemes"
                        :key="uuidOf(theme)"
                        :value="uuidOf(theme)"
                        :label="theme.name"
                    />
                </el-select>
            </div>

            <div class="flex-1">
                <!--spacer-->
            </div>

            <Marker v-if="true"
                    :show-green-marker="true"
                    :show-red-marker="true"
                    v-on:red="sendEvent('red')"
                    v-on:green="sendEvent('green')"
            />

            <div class="flex-1">
                <!--spacer-->
            </div>

            <StatusSelector :multi="true"
                            v-model:statusSet="statusSet"
            />
        </div>

        <template v-if="loadingSub">
            <el-skeleton animated :count="3">
                <template #template>
                    <el-skeleton-item class="h-44"/>
                </template>
            </el-skeleton>
        </template>
        <template v-else>

            <template v-if="USE_VLIST">
                <VList
                    :data="allKpis"
                    :style="{ height: '800px' }"
                    #default="{ item, index }"
                >
                    <div>
                        <AdminReportKpi
                            :key="index"
                            :kpi="item"
                            :reportitems="allReportItemsFor(item)"
                        />
                    </div>
                </VList>
            </template>

            <template v-else>

                <AdminReportKpi
                    v-for="kpi in allKpis"
                    :key="uuidOf(kpi)"
                    :kpi="kpi"
                    :reportitems="allReportItemsFor(kpi)"
                />
            </template>

        </template>
    </div>
</template>
