<script setup lang="ts">
import {computed, onMounted, ref, watch} from 'vue';
import {ElInput} from 'element-plus';
import {CabObj, Filter} from 'common';

const props = defineProps({
    cabList        : Array as () => CabObj[],
    cabListFiltered: Array as () => CabObj[],
    active         : Boolean,
    text           : String,
    filter         : Filter,
    id             : String,
});

const emit = defineEmits(['update:active', 'update:text', 'update:cabListFiltered', 'update:filter']);

const cabListFiltered_ = computed<CabObj[]>(() => filter.value.filter(props.cabList || []));
const active           = ref<boolean>(false);
const text             = ref<string>('');
const filter           = computed<Filter>(() => new Filter(active.value, text.value));
const numTotal         = computed<number>(() => props.cabList?.length || 0);
const numShown         = computed<number>(() => props.cabListFiltered?.length || 0);
const storageId        = computed<string>(() => 'FILTER-STATE' + (props.id ? `:${props.id}` : ''));

onMounted(() => {
    restoreState();
    emit('update:cabListFiltered', cabListFiltered_.value)
});
watch(storageId, () => restoreState());

watch(cabListFiltered_, x => emit('update:cabListFiltered', x));
watch(() => props.active, x => active.value = x ?? false);
watch(active, x => {
    saveState();
    emit('update:active', x);
});
watch(() => props.text, x => text.value = x ?? '');
watch(text, x => {
    saveState();
    emit('update:text', x);
});
watch(filter, x => emit('update:filter', x));

function saveState() {
    const state = {
        a: active.value,
        t: text.value,
    };
    localStorage.setItem(storageId.value, JSON.stringify(state));
}

function restoreState() {
    const state = localStorage.getItem(storageId.value);
    if (state) {
        const {
                  a,
                  t,
              }      = JSON.parse(state);
        active.value = a;
        text.value   = t;
    }
}

</script>

<template>
    <div class="flex flex-row items-center rounded-md py-0 px-3 h-10"
         :style="{ backgroundColor: 'var(--el-color-primary)' }">

        <transition name="width-transition">
            <div v-if="active" class="flex flex-row w-[300px] text-sm">
                <el-input
                    class="h-6 mr-2"
                    v-model="text"
                    type="text"
                    :autosize="{ minRows: 1, maxRows: 1 }"
                    placeholder="filter text"
                    title="begin met ! om te inverteren"
                    clearable
                />
                <div v-if="active" class="text-white  whitespace-nowrap px-2 pt-0.5">
                    {{ numShown }} van {{ numTotal }}
                </div>
            </div>
        </transition>

        <svg @click="active = !active"
             fill="#ffffff"
             stroke-width="1"
             stroke="#000000"
             viewBox="0 0 24 24"
             width="26px"
             height="26px"
             xmlns="http://www.w3.org/2000/svg"
        >
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z"/>
        </svg>

    </div>
</template>

<!--suppress CssUnusedSymbol -->
<style scoped>
.width-transition-enter-active,
.width-transition-leave-active {
    transition: opacity 300ms ease, width 300ms ease;
}

.width-transition-enter-from,
.width-transition-leave-to {
    width: 0;
    overflow: hidden;
    opacity: 0;
}

.width-transition-enter-to,
.width-transition-leave-from {
    width: 300px;
    opacity: 1;
}
</style>
