import {CabMe, FAKE_TIME_SKEW_ERROR} from 'common';
import * as Session from 'supertokens-web-js/recipe/session/index.js';
import {getMe} from '@/base/callBackend.js';


let me: CabMe | undefined;
let meRefreshTick: number            = 0;
const ME_EXPIRE_TIME: number         = 1000 * 60 * 60;
const MAX_DIFF_TO_BE_IN_SYNC: number = 1000 * 60 * 5;

export async function getMeInfo(): Promise<CabMe | undefined> {
    if (!await Session.doesSessionExist()) {
        me            = undefined;
        meRefreshTick = 0;
    } else if (!me || meRefreshTick <= Date.now()) {
        me = await getMe();
        if (me) {
            const now: number        = Date.now();
            me.serverBehindLocalTime = now - me.serverTimestamp + (FAKE_TIME_SKEW_ERROR ? 4_711_000 : 0);
            meRefreshTick            = now + ME_EXPIRE_TIME;
        }
    }
    return me;
}

export async function getMePermission(): Promise<undefined | string[]> {
    const meInfo = await getMeInfo();
    return meInfo?.permissions ?? undefined;
}

export async function meHasPermission(requiredPerm: string): Promise<boolean | undefined> {
    const mePerm = await getMePermission();
    return mePerm?.includes(requiredPerm) ?? false;
}

export async function serverTimeSyncsWithMine(maxTickDistance = MAX_DIFF_TO_BE_IN_SYNC): Promise<boolean> {
    const meInfo = await getMeInfo();
    return !!meInfo && Math.abs(meInfo.serverBehindLocalTime) < maxTickDistance;
}
