<script setup lang="ts">
import {onMounted, ref} from 'vue';
import {useRouter} from 'vue-router';
import {sendPasswordResetEmail, signIn} from 'supertokens-web-js/recipe/emailpassword';
import * as Session from 'supertokens-web-js/recipe/session';
import ZZC from '@/views/header/ZZCLogo.vue';
import {getAppVersion} from '@/base/frontendConfig';
import {getMePermission} from '@/base/me.js';

type ReportState = { text: string, error: boolean };

const router      = useRouter();
const email       = ref('');
const password    = ref('');
const reportState = ref<ReportState>({
    text : '',
    error: false,
});

let errorTimerId: number | ReturnType<typeof setTimeout>;

onMounted(async () => {
    await permissionRedirect();
});

function onDashboard() {
    window.location.href = '/auth/dashboard';
}

async function onLogin() {
    if (setReportState(await login())) {
        await permissionRedirect();
    }
}

async function onPwReset() {
    setReportState(await passwordReset());
}

function makeError(text: string): ReportState {
    return {
        text,
        error: true,
    };
}

function makeOk(text?: string): ReportState {
    return {
        text : text ?? '',
        error: false,
    };
}

function setReportState(state: ReportState) {
    clrReportState();
    if (state.text) {
        reportState.value = state;
        if (errorTimerId) {
            clearTimeout(errorTimerId);
        }
        errorTimerId = setTimeout(clrReportState, 5_000);
    }
    return !state.error;
}

function clrReportState() {
    reportState.value = makeOk();
    if (errorTimerId) {
        clearTimeout(errorTimerId);
    }
}

async function permissionRedirect() {
    const permissions = await getMePermission();
    if (permissions) {
        if (permissions.includes('admin')) {
            await router.push('/admin');
        } else if (permissions.includes('report')) {
            await router.push('/report');
        }
    }
}

async function login(): Promise<ReportState> {
    try {
        const answer = await signIn({
            formFields: [
                {
                    id   : 'email',
                    value: email.value,
                },
                {
                    id   : 'password',
                    value: password.value,
                },
            ],
        });
        switch (answer.status) {
            case 'OK': {
                if (await Session.doesSessionExist()) {
                    return makeOk();
                } else {
                    return makeError('inloggen niet gelukt');
                }
            }
            case 'FIELD_ERROR': {
                const msg = answer.formFields[0].error;
                if (msg === 'Field is not optional') {
                    const what = answer.formFields.map((i) => i.id).sort().join('&');
                    return makeError(`${what} invullen`);
                } else {
                    return makeError(msg);
                }
            }
            case 'WRONG_CREDENTIALS_ERROR':
                return makeError('Incorrect email/password');
            default:
                console.error(answer.status);
                return makeError('fout opgetreden');
        }
    } catch (err) {
        console.error(err);
        return makeError('fout opgetreden');
    }
}

async function passwordReset(): Promise<ReportState> {
    try {
        const answer = await sendPasswordResetEmail({
            formFields: [
                {
                    id   : 'email',
                    value: email.value,
                },
            ],
        });
        console.log('passwordReset answer=', answer);
        if (answer.status !== 'OK') {
            switch (answer.status) {
                case 'FIELD_ERROR': {
                    const msg = answer.formFields[0].error;
                    if (msg === 'Field is not optional') {
                        const what = answer.formFields.map((i) => i.id).sort().join('&');
                        return makeError(`${what} invullen`);
                    } else {
                        return makeError(msg);
                    }
                }
                default:
                    console.error(answer.status);
                    return makeError('fout opgetreden');
            }
        }
        return makeOk('mail verzonden');
    } catch (err) {
        console.error(err);
        return makeError('fout opgetreden');
    }
}
</script>

<template>
    <div class="z-50 absolute top-[12px] left-[12px] px-2 text-xs text-center">
        {{ getAppVersion() }}
    </div>
    <div class="min-h-screen flex items-center justify-center">
        <div
            @keydown.enter="onLogin"
            class="relative flex flex-col w-[440px] mx-auto p-8 bg-white rounded-lg shadow-2xl between space-y-4"
        >
            <ZZC/>
            <div class="text-2xl font-bold text-center">
                Rapportage Login
            </div>
            <div>
                <label
                    for="email"
                    class="block text-sm font-medium text-gray-700 cursor-pointer"
                >
                    email
                </label>
                <input
                    id="email"
                    v-model="email"
                    type="text"
                    class="mt-1 px-3 py-1 block w-full rounded-md shadow-sm border border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
            </div>
            <div>
                <label
                    for="password"
                    class="block text-sm font-medium text-gray-700 cursor-pointer"
                >
                    password
                </label>
                <input
                    id="password"
                    v-model="password"
                    type="password"
                    class="mt-1 px-3 py-1 block w-full rounded-md shadow-sm border border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
            </div>
            <div class="flex flex-row justify-between items-center w-full">
                <Transition
                    enter-from-class="opacity-0"
                    enter-active-class="transition-opacity duration-300 ease-out"
                    enter-to-class="opacity-100"
                    leave-from-class="opacity-100"
                    leave-active-class="transition-opacity duration-1000 ease-in"
                    leave-to-class="opacity-0"
                >
                    <div v-if="reportState.text" class="py-1 px-2 text-white"
                         :class="reportState.error?'bg-red-600':'bg-green-600'">
                        {{ reportState.text }}
                    </div>
                </Transition>
                <div v-if="!reportState.text" class="py-1 px-2">
                </div>
                <button
                    @click="onPwReset"
                    class="w-min hover:bg-blue-700 hover:text-white py-2 px-4 rounded cursor-pointer"
                >
                    Password&nbsp;Reset
                </button>
            </div>
            <div class="flex items-center">
                <button
                    @click="onLogin"
                    class="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer"
                >
                    Login
                </button>
                <div class="absolute top-0 left-0 w-3 h-3"
                     @click="onDashboard">
                </div>
            </div>
        </div>
    </div>
</template>
