// noinspection JSUnusedGlobalSymbols,HttpUrlsUsage

import Session from 'supertokens-web-js/recipe/session';
import EmailPassword from 'supertokens-web-js/recipe/emailpassword';
import Multitenacy from 'supertokens-web-js/recipe/multitenancy';

const MARKER               = '_'.repeat(3) + 'ENV:';
let envMarkerWarningIssued = false;

function env(envName: string, defaultValue: string): string {
    if (!envName.startsWith(MARKER)) {
        return envName ? envName : defaultValue;
    } else {
        if (!envMarkerWarningIssued) {
            envMarkerWarningIssued = true;
            console.warn(`WARNING: ENV markers still in the code (${envName} -> ${defaultValue})`);
        }
        return defaultValue;
    }
}

function makeUrl(host: string, port: string) {
    if (port === '443') {
        return `https://${host}`;
    } else if (port === '80') {
        // noinspection HttpUrlsUsage
        return `http://${host}`;
    } else {
        // noinspection HttpUrlsUsage
        return `http://${host}:${port}`;
    }
}

////////////////////////////////////////////////////////////////////////////////////////////////
export function getAppName() {
    return env('___ENV:APP_NAME___', 'cab');
}

export function getAppVersion() {
    return env('___ENV:APP_VERSION___', 'dev-build');
}

////////////////////////////////////////////////////////////////////////////////////////////////
export function getServicePort() {
    return env('___ENV:SERVICE_PORT___', '3001');
}

////////////////////////////////////////////////////////////////////////////////////////////////
export function getSupertokensCorePort() {
    return env('___ENV:SUPERTOKENS_CORE_PORT___', '3567');
}

export function getSupertokensCoreHost() {
    return env('___ENV:SUPERTOKENS_CORE_HOST___', 'localhost');
}

export function getSupertokensCoreUrl() {
    return env('___ENV:SUPERTOKENS_CORE_URL___', makeUrl(getSupertokensCoreHost(), getSupertokensCorePort()));
}

////////////////////////////////////////////////////////////////////////////////////////////////
export function getBackendHost() {
    return env('___ENV:BACKEND_HOST___', 'localhost');
}

export function getBackendPort() {
    return env('___ENV:BACKEND_PORT___', '3001');
}

export function getBackendUrl() {
    return env('___ENV:BACKEND_URL___', makeUrl(getBackendHost(), getBackendPort()));
}

////////////////////////////////////////////////////////////////////////////////////////////////
export function getFrontendPort() {
    return env('___ENV:FRONTEND_PORT___', '3000');
}

export function getFrontendHost() {
    return env('___ENV:FRONTEND_HOST___', 'localhost');
}

export function getFrontendUrl() {
    return env('___ENV:FRONTEND_URL___', makeUrl(getFrontendHost(), getFrontendPort()));
}

////////////////////////////////////////////////////////////////////////////////////////////////
export const SuperTokensConfigFrontend = {
    appInfo   : {
        appName  : getAppName(),
        apiDomain: getBackendUrl(),
    },
    recipeList: [
        Session.init(),
        EmailPassword.init(),
        // EmailVerification.init(),
        Multitenacy.init(), // this was suddenly needed to avoid an error at login... no idea why
    ],
};
