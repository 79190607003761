<script setup lang="ts">
import {computed, onMounted, PropType, ref, watch} from 'vue';
import {
    CabPeriod,
    CabReportItem,
    OnderwerpEnum,
    OnderwerpEnum_name,
    PeriodEnum,
    renderCabPeriod,
    renderTerseDate,
    StatusEnum,
} from 'common';
import {ElBadge, ElButton, ElDialog, ElPopover} from 'element-plus';
import {listReporterReportItems, updateReportItem} from '@/base/callBackend.js';
import LoadingStateView from './LoadingStateView.vue';
import {done, LoadingState, loadingState, reload} from '@/base/loadingState.js';
import {localStorageGetJson, localStorageSetJson, ReportGroup, SelectGroupInfo} from '@/base/utils.js';
import {newOverallNum} from '@/base/reporter.js';
import SelectorGroup from '@/views/report/SelectorGroup.vue';
import ReportItemStrip from '@/views/report/ReportItemStrip.vue';
import KpiIcon from '@/views/icons/KpiIcon.vue';
import ExprIcons from '@/views/report/ExprIcons.vue';
import CalculatorIcon from '@/views/icons/CalculatorIcon.vue';
import HandIcon from '@/views/icons/HandIcon.vue';
import InfoIcon from '@/views/icons/InfoIcon.vue';

const props = defineProps({
    reportGroup: {
        type    : String as PropType<ReportGroup>,
        required: true,
    },
});

//============================================================================================================================================
const all                      = ref<CabReportItem[]>([]);
const allNum                   = computed<number>(() => all.value.length);
const unsubmittableNum         = computed<number>(() => all.value.filter(ri => !ri.isSubmittable()).length);
const instellingToNumMap       = computed<Map<string | number, number>>(() => makeNumberMap(instellingGetter, all.value));
const selectedInstellingenList = ref<string[]>([]);
const allNaInstellingen        = computed<CabReportItem[]>(() => all.value.filter(ri => selectedInstellingenList.value.includes(ri.organization[0].uuid)));
const themaToNumMap            = computed<Map<string | number, number>>(() => makeNumberMap(themaGetter, allNaInstellingen.value));
const selectedThemasList       = ref<string[]>([]);
const allNaThemas              = computed<CabReportItem[]>(() => allNaInstellingen.value.filter(ri => selectedThemasList.value.includes(ri.theme[0].uuid)));
const onderwerpToNumMap        = computed<Map<string | number, number>>(() => makeNumberMap(onderwerpGetter, allNaThemas.value));
const selectedOnderwerpenList  = ref<OnderwerpEnum[]>([]);
const allNaOnderwerpen         = computed<CabReportItem[]>(() => allNaThemas.value.filter(ri => selectedOnderwerpenList.value.includes(ri.kpi[0].onderwerp!)));
const dateList                 = computed<string[]>(() => new Array(...new Set(all.value.map(ri => renderIndienMoment(ri)))).toSorted((a, b) => a.localeCompare(b)));
const dateToNumMap             = computed<Map<string | number, number>>(() => makeNumberMap(dateGetter, allNaOnderwerpen.value));
const selectedDate             = ref<string>();
const allNaDate                = computed<CabReportItem[]>(() => allNaOnderwerpen.value.filter(ri => selectedDate.value === renderIndienMoment(ri)));
const cursorLine               = ref<number>();
const editIndex                = ref<number>();
const editReportItem           = computed<CabReportItem | undefined>(() => editIndex.value != undefined ? allNaDate.value[editIndex.value] : undefined);
const dialogVisible            = ref<boolean>();
const flagOmissions            = ref<boolean>(true);
const indienenInProgress       = ref<boolean>(false);
const thisIsHeden              = computed<boolean>(() => props.reportGroup === ReportGroup.heden);

watch(allNum, () => newOverallNum(props.reportGroup, allNum.value));
watch(selectedDate, () => localStorageSetJson(`${props.reportGroup}:selectedDate`, selectedDate.value));
watch(dateList, () => {
    if (!selectedDate.value) {
        const saved        = localStorageGetJson(`${props.reportGroup}:selectedDate`);
        selectedDate.value = saved && dateList.value.includes(saved) ? saved : dateList.value[0];
    }
});
onMounted(async () => {
    await getAllFromBackend();
});

async function getAllFromBackend() {
    await reload(async () => {
        all.value = (await listReporterReportItems(props.reportGroup)).sort((a, b) => a.compare(b));
    });
}

//============================================================================================================================================
function makeNumberMap(f: (ri: CabReportItem) => SelectGroupInfo, riList: CabReportItem[]) {
    return new Map(
        riList.reduce((acc, ri) => {
            const id = f(ri).id;
            acc.set(id, (acc.get(id) || 0) + 1);
            return acc;
        }, new Map<string | number, number>()),
    );
}

function instellingGetter(ri: CabReportItem): SelectGroupInfo {
    const x = ri.organization[0];
    return {
        name: x.name,
        id  : x.uuid,
    };
}

function themaGetter(ri: CabReportItem): SelectGroupInfo {
    const x = ri.theme[0];
    return {
        name: x.name,
        id  : x.uuid,
    };
}

function onderwerpGetter(ri: CabReportItem): SelectGroupInfo {
    const x = ri.kpi[0].onderwerp!;
    return {
        name: OnderwerpEnum_name(x),
        id  : x,
    };
}

function dateGetter(ri: CabReportItem): SelectGroupInfo {
    const x = renderIndienMoment(ri);
    return {
        name: x,
        id  : x,
    };
}

function getInstellingBadge(a: string | number) {
    return instellingToNumMap.value.get(a as string)!;
}

function getThemaBadge(a: string | number) {
    return themaToNumMap.value.get(a as string)!;
}

function getOnderwerpBadge(a: string | number) {
    return onderwerpToNumMap.value.get(a as number)!;
}

function getDateBadge(a: string | number) {
    return dateToNumMap.value.get(a as number)!;
}

//============================================================================================================================================
async function onAllesIndienen() {
    indienenInProgress.value = true;
    for (const ri of all.value) {
        ri.status = StatusEnum.submitted;
        await updateReportItem(ri);
    }
    window.location.reload();
}

//============================================================================================================================================
function renderIndienMoment(ri: CabReportItem) {
    if (ri.isMijlpaal) {
        return renderCabPeriod(CabPeriod.of(ri.meetMoment!, PeriodEnum.Q).previousPeriod());
    } else {
        return renderCabPeriod(ri.period[0].qPeriod());
    }
}

function dynLineClass(i: number): string {
    const onEvenLine   = i % 2 == 0;
    const onCursorLine = i == cursorLine.value;
    return onEvenLine ? onCursorLine ? 'bg-green-300' : 'bg-green-200' : onCursorLine ? 'bg-green-300' : 'bg-green-100';
}

function bolletje(ri: CabReportItem) {
    switch (props.reportGroup) {
        case ReportGroup.verleden:
            return ri.status === StatusEnum.submitted ? '🔵' : '🟣';
        case ReportGroup.heden:
            return ri.isSubmittable() ? '🟢' : '🟠';
        case ReportGroup.toekomst:
            return '⚪️';
    }
}

</script>

<template>
    <div class="w-full h-full relative">

        <div class="pt-10">
            <template v-if="loadingState!==LoadingState.CLOSED">
                <SelectorGroup
                    :all="all"
                    :names="['instelling','instellingen']"
                    :local-storage-name="`${reportGroup}:selectedInstellingen`"
                    :select-field="instellingGetter"
                    v-model:selected-list="selectedInstellingenList"
                    :show-badges="thisIsHeden"
                    :get-badge="getInstellingBadge"
                />
                <SelectorGroup
                    :all="all"
                    :names="['thema','themas']"
                    :local-storage-name="`${reportGroup}:selectedThemas`"
                    :select-field="themaGetter"
                    v-model:selected-list="selectedThemasList"
                    :show-badges="thisIsHeden"
                    :get-badge="getThemaBadge"
                />
                <SelectorGroup
                    :all="all"
                    :names="['onderwerp','onderwerpen']"
                    :local-storage-name="`${reportGroup}:selectedOnderwerpen`"
                    :select-field="onderwerpGetter"
                    v-model:selected-list="selectedOnderwerpenList"
                    :show-badges="thisIsHeden"
                    :get-badge="getOnderwerpBadge"
                />
            </template>
        </div>

        <div class="flex py-5 px-20">

            <LoadingStateView :state="loadingState"/>

            <!-- Datum selectie labels -->
            <div class="w-44 my-6" v-if="done">
                <div v-for="n in dateList"
                     :key="n"
                     class="pl-2 pr-8 py-4 my-1 text-right"
                     :class="selectedDate === n ? 'bg-white font-bold cursor-default' : 'font-thin hover:bg-gray-200 cursor-pointer'"
                     @click="selectedDate = n"
                >
                    <el-badge
                        :value="done ? getDateBadge(n) : undefined"
                        :class="n === selectedDate ? 'cursor-default' : 'cursor-pointer'"
                        :max="99"
                        :offset="[10,2]"
                        :hidden="!thisIsHeden"
                        badge-class="font-normal"
                    >
                        {{ n }}
                    </el-badge>
                </div>
            </div>

            <!-- Content -->
            <div class="w-full border-8 border-white" v-if="done">
                <div class="overflow-x-auto">
                    <div v-if="all.length===0"
                         class="font-thin text-gray-500 text-xl text-center m-10 h-72"
                    >
                        - op dit moment zijn er geen items die ingediend kunnen worden -
                    </div>
                    <div v-else-if="allNaDate.length===0"
                         class="font-thin text-gray-500 text-xl text-center m-10 h-72"
                    >
                        - lege selectie -
                    </div>
                    <table class="table-auto border-collapse border w-full cursor-pointer">
                        <tbody>
                        <tr v-for="(ri,i) in allNaDate"
                            :key="ri.uuid"
                            class=""
                            :class="dynLineClass(i)"
                            @mouseenter="cursorLine = i"
                            @mouseleave="cursorLine = -1"
                            @click="editIndex = i; dialogVisible = true"
                        >
                            <td class="whitespace-nowrap text-center border-y border-gray-300 py-2 px-2">
                                {{ bolletje(ri) }}
                            </td>
                            <td class="whitespace-nowrap text-center border-y border-gray-300 py-2 px-3">
                                {{ ri.organization[0].name }}
                            </td>
                            <td class="whitespace-nowrap text-center border-y border-gray-300 py-2 px-3">
                                {{ ri.theme[0].name }}
                            </td>
                            <td class="whitespace-nowrap text-center border-y border-gray-300 py-2 px-3">
                                {{ OnderwerpEnum_name(ri.kpi[0].onderwerp) }}
                            </td>
                            <td class="                  text-left   border-y border-gray-300 py-2 pl-3">
                                <KpiIcon :kpi="ri.kpi[0]"/>
                            </td>
                            <td class="                  text-left   border-y border-gray-300 py-2 pl-0 pr-3 font-bold w-full">
                                {{ ri.kpi[0].name }}
                            </td>
                            <td class="                  text-left   border-y border-gray-300 py-2 pl-0 pr-3 font-bold w-full">
                                <ExprIcons :kpi="ri.kpi[0]"/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="flex flex-row py-0 pl-60 cursor-pointer">
            <el-badge
                :value="unsubmittableNum"
                :max="99"
                badge-class="font-normal"
                :show-zero="false"
                v-if="thisIsHeden && all.length!=0"
            >
                <el-button type="primary"
                           @click="onAllesIndienen()"
                           :disabled="unsubmittableNum!=0 || indienenInProgress"
                           :title="unsubmittableNum!=0?'Zorg eerst dat alle items indienbaar zijn.':`Alle ${allNum} items worden ingediend`"
                >
                    Alle {{ allNum }} Items Indienen (je moet er nog {{ unsubmittableNum }} invullen)
                </el-button>
            </el-badge>
        </div>

        <div class="absolute top-0 right-0 pr-8 pt-2 cursor-default">
            <el-popover
                :width="400"
                popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 10px;"
            >
                <template #reference>
                    <InfoIcon/>
                </template>

                <div class="pb-2">
                    Verklaring der symbolen:
                </div>
                <table class="table-auto border-collapse border w-full cursor-pointer">
                    <tbody>
                    <tr>
                        <td class="whitespace-nowrap py-1 pl-4 text-lg">🟠</td>
                        <td class="whitespace-nowrap py-1 px-3">er moet nog iets ingevuld worden</td>
                    </tr>
                    <tr>
                        <td class="whitespace-nowrap py-1 pl-4 text-lg">🟢</td>
                        <td class="whitespace-nowrap py-1 px-3">item klaar om ingediend te worden</td>
                    </tr>
                    <tr>
                        <td class="whitespace-nowrap py-1 pl-4 text-lg">🔵</td>
                        <td class="whitespace-nowrap py-1 px-3">ingediend item</td>
                    </tr>
                    <tr>
                        <td class="whitespace-nowrap py-1 pl-4 text-lg">🟣</td>
                        <td class="whitespace-nowrap py-1 px-3">geaccepteerd item</td>
                    </tr>
                    <tr>
                        <td class="whitespace-nowrap py-1 pl-4 text-lg">⚪</td>
                        <td class="whitespace-nowrap py-1 px-3">toekomstig item</td>
                    </tr>
                    <tr>
                        <td class="whitespace-nowrap py-1 px-3 text-lg">
                            <CalculatorIcon/>
                        </td>
                        <td class="whitespace-nowrap py-1 px-3">berekende waarde</td>
                    </tr>
                    <tr>
                        <td class="whitespace-nowrap py-1 px-3 text-lg">
                            <CalculatorIcon :crippled="true"/>
                        </td>
                        <td class="whitespace-nowrap py-1 px-3">berekende waarde (nog geen expressie)</td>
                    </tr>
                    <tr>
                        <td class="whitespace-nowrap py-1 px-3 text-lg">
                            <HandIcon/>
                        </td>
                        <td class="whitespace-nowrap py-1 px-3">berekende status</td>
                    </tr>
                    </tbody>
                </table>
            </el-popover>
        </div>

    </div>

    <el-dialog
        v-model="dialogVisible"
        width="90%"
        align-center
        destroy-on-close
        :show-close="false"
        v-if="editReportItem" :draggable="true"
    >
        <template #header>
            <div class="flex flex-row items-center text-lg">
                <div class="cursor-default mx-2 whitespace-nowrap">
                    {{ editReportItem.isSubmittable() ? '🟢' : '🟠' }}
                </div>
                <div class="mx-2 whitespace-nowrap">
                    {{ editReportItem.organization[0].name }}
                </div>
                <div class="mx-2 whitespace-nowrap">
                    {{ editReportItem.theme[0].name }}
                </div>
                <div class="mx-2 whitespace-nowrap">
                    {{ OnderwerpEnum_name(editReportItem.kpi[0].onderwerp) }}
                </div>
                <KpiIcon :kpi="editReportItem.kpi[0]" class="ml-2"/>
                <div class="font-bold flex-wrap">
                    {{ editReportItem.kpi[0].name }}
                </div>
                <div class="flex-1">
                    <!--filler-->
                </div>

                <div class="mx-2 px-4 bg-gray-200 whitespace-nowrap">
                    {{ editReportItem.isMijlpaal ? `per ${renderTerseDate(editReportItem.meetMoment)}` : `over ${renderCabPeriod(editReportItem.period[0])}`
                    }}
                </div>
            </div>
        </template>

        <ReportItemStrip
            :flag-omissions="flagOmissions"
            :reportitem="editReportItem"
            @refresh-reports="getAllFromBackend"
        />

    </el-dialog>
</template>

<style scoped>
/*noinspection CssUnusedSymbol*/
.el-button:disabled {
    @apply bg-gray-400 text-gray-200;
}

</style>
