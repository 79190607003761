<script setup lang="ts">
import {computed, onMounted, type PropType, ref} from 'vue';
import {ElDialog, ElSkeleton, ElSkeletonItem} from 'element-plus';
import {CabKPI, type CabOrganization, OnderwerpEnum, PrognoseSchaalEnum, uuidOf, WaardeTypeEnum} from 'common';
// noinspection ES6UnusedImports - usages in <template> are not properly detected!
import {KPI_CHANGE_EMIT, KPI_REMOVE_EMIT} from '@/base/cabConstants';
import {deleteKpi, expressieDoc, listKpis, listOrganizations, updateKpi} from '@/base/callBackend';
import KPIStrip from '@/views/admin/KPIStrip.vue';
import PlusButton from '@/views/icons/PlusIcon.vue';
import {KpiSelectEnum} from '@/base/cabRouter';
import GeneralFilter from '@/views/general/GeneralFilter.vue';
import MarkdownIt from 'markdown-it';
import InfoIcon from '@/views/icons/InfoIcon.vue';

const props = defineProps({
    kpiSelect: {
        type    : Number as PropType<KpiSelectEnum>,
        required: true,
    },
});

const loading              = ref(true);
const allOrganizations     = ref<CabOrganization[]>([]);
const allKPIs              = ref<CabKPI[]>([]);
const kpis                 = computed(() => allKPIs.value.filter(k => doingMilestones() == k.isMijlpaal()));
const filterActive         = ref<boolean>(false);
const filteredKpis         = ref<CabKPI[]>([]);
const filterId             = computed<string>(() => doingMilestones() ? 'MilestoneList' : 'KPIList');
const featureDialogVisible = ref(false);
const content              = ref('');
const md                   = new MarkdownIt({
    linkify: true,
});

onMounted(async () => {
    allOrganizations.value = await listOrganizations();
    allKPIs.value          = await listKpis();
    loading.value          = false;
});

//=====================================================================================
function doingMilestones(): boolean {
    return props.kpiSelect === KpiSelectEnum.onlyMilestones;
}

async function onKPIAdd() {
    filterActive.value   = false;
    const newKPI: CabKPI = new CabKPI();
    if (doingMilestones()) {
        newKPI.onderwerp           = OnderwerpEnum.mijlpaal;
        newKPI.waardeType          = undefined;
        newKPI.prognoseSchaal      = PrognoseSchaalEnum.once;
        newKPI.hasDoelwaarde       = false;
        newKPI.askKpiStatus        = false;
        newKPI.isCalculatedValue   = false;
        newKPI.hasUitkeringsbedrag = true;
    } else {
        newKPI.onderwerp           = OnderwerpEnum.adherentie;
        newKPI.waardeType          = WaardeTypeEnum.getal;
        newKPI.prognoseSchaal      = PrognoseSchaalEnum.none;
        newKPI.hasDoelwaarde       = false;
        newKPI.askKpiStatus        = false;
        newKPI.isCalculatedValue   = false;
        newKPI.hasUitkeringsbedrag = false;
    }
    await updateKpi(newKPI);
    allKPIs.value = [newKPI, ...allKPIs.value];
}

async function onKPIRemove(kpi: CabKPI) {
    await deleteKpi(kpi);
    allKPIs.value = allKPIs.value.filter(k => uuidOf(k) !== uuidOf(kpi));
}

async function onKPIChange(kpi: CabKPI) {
    const uuid   = uuidOf(kpi);
    const kpiNew = await updateKpi(kpi);

    if (kpiNew) {
        const kpiIndex = allKPIs.value.findIndex((u) => uuidOf(u) === uuid);
        if (kpiIndex !== -1) {
            // this rather verbose replace of the kpi is needed to trigger vue's reactivety mechanism...
            allKPIs.value = [
                ...allKPIs.value.slice(0, kpiIndex),
                kpiNew,
                ...allKPIs.value.slice(kpiIndex + 1),
            ];
        }
    }
}

async function showInfo() {
    content.value              = await expressieDoc();
    featureDialogVisible.value = true;
}
</script>

<template>
    <div class="flex flex-col flex-1 mx-28 my-5">
        <div class="flex flex-row flex-1 mb-1">
            <PlusButton @click="onKPIAdd()"/>
            <div class="text-2xl  ml-2">
                {{ doingMilestones() ? 'Mijlpalen' : 'KPI\'s' }}
            </div>
            <InfoIcon class="ml-4 mt-1 cursor-pointer"
                @click="showInfo()"/>
            <div class="flex-1">

            </div>
            <GeneralFilter v-model:active="filterActive"
                           v-model:cabList="kpis"
                           v-model:cabListFiltered="filteredKpis"
                           :id="filterId"/>
        </div>
        <div v-if="loading">
            <el-skeleton :rows="5" animated :count="5">
                <template #template>
                    <el-skeleton-item class="h-[69px] m-0.5"/>
                </template>
            </el-skeleton>
        </div>
        <KPIStrip
            v-else
            v-for="kpi in filteredKpis"
            :key="uuidOf(kpi)"
            :kpi="kpi"
            :allOrganizations="allOrganizations"
            v-on:[KPI_REMOVE_EMIT]="onKPIRemove"
            v-on:[KPI_CHANGE_EMIT]="onKPIChange"
        />
    </div>


    <el-dialog
        v-model="featureDialogVisible"
        :show-close="false"
        :close-on-press-escape="true"
        :close-on-click-modal="true"
    >
        <template #header>
            <div class="text-xl text-center w-full bg-gray-700 text-gray-200 py-2">
                Expressie documentatie
            </div>
        </template>
        <div class="flex flex-col min-w-60 bg-gray-300 p-3">
            <div class="prose">
                <div v-html="md.render(content)"></div>
            </div>
        </div>
    </el-dialog>
</template>

<style>
.prose h1 {
    @apply text-3xl font-bold text-gray-700 mt-5;
}
.prose h2 {
    @apply text-2xl font-semibold text-gray-700 mt-4;
}
.prose h3 {
    @apply text-xl font-medium text-gray-700 mt-3;
}
.prose p {
    @apply mt-2;
}
.prose ul {
    @apply my-3  mx-20;
}
.prose ol {
    @apply my-3  mx-20;
}
.prose table {
    @apply my-3  mx-20;
}
.prose th {
    @apply p-1 px-5 bg-white;
}
.prose td {
    @apply p-1 px-5 bg-gray-200;
}
.prose pre {
    @apply my-3 mx-10 px-3 p-1 bg-gray-200;
}

</style>
