<script setup lang="ts">
import {onMounted, type PropType, ref, watch} from 'vue';
import {
    type CabReportItem,
    enum_values,
    MijlpaalEnum,
    MijlpaalEnum_name,
    MijlpaalPrognoseEnum,
    MijlpaalPrognoseEnum_name,
    renderTerseDate,
    StatusEnum,
    StatusEnum_color,
    WaardeTypeEnum,
} from 'common';
import {readAdminItem, updateAdminItem} from '@/base/callBackend';
import WaardeInvul from '@/views/general/WaardeInvul.vue';
import {ElInput, ElOption, ElSelect} from 'element-plus';
import StatusSelector from '@/views/general/StatusSelector.vue';

//===================================================================================================================
const props = defineProps({
    reportitem: {
        type    : Object as PropType<CabReportItem>,
        required: true,
    },
});

const theValue            = ref<MijlpaalEnum | undefined>(props.reportitem.reportedValue);
const theUitkeringsbedrag = ref(props.reportitem.uitkeringsbedrag);
const thePrognose         = ref<number | MijlpaalPrognoseEnum | undefined>(props.reportitem.prognose);
const statusTarget        = ref<StatusEnum[]>([]);

//=================================================================================================
onMounted(() => {
    statusTarget.value = [props.reportitem.status!];
});

//=================================================================================================
watch(theValue, (v) => {
    reportedValueChanged(v);
});
watch(theUitkeringsbedrag, (v) => {
    uitkeringsbedragChanged(v);
});
watch(thePrognose, (v) => {
    prognoseChanged(v);
});
watch(statusTarget, async (nv, ov) => {
    if (ov[0]) {
        const newValue        = nv[0];
        const resultingStatus = await onStatusChange(newValue);
        if (resultingStatus !== newValue) {
            statusTarget.value = [resultingStatus];
        }
    }
});

//=================================================================================================
async function reportedValueChanged(v: number | MijlpaalEnum | undefined) {
    if (v !== theValue.value || v !== props.reportitem.reportedValue) {
        theValue.value                 = v;
        props.reportitem.reportedValue = v;
        await onChange();
    }
}

async function prognoseChanged(v: number | MijlpaalPrognoseEnum | undefined) {
    if (v !== thePrognose.value || v !== props.reportitem.prognose) {
        thePrognose.value         = v;
        props.reportitem.prognose = v;
        await onChange();
    }
}

function keyupOnTheValue(event: any) {
    if (event.key === 'Backspace' || event.key === 'Delete') {
        theValue.value = undefined;
        event.originalTarget.blur();
    }
}

function keyupOnThePrognose(event: any) {
    if (event.key === 'Backspace' || event.key === 'Delete') {
        thePrognose.value = undefined;
        event.originalTarget.blur();
    }
}

async function onChange() {
    await updateAdminItem(props.reportitem);
}

//===================================================================================================================
async function uitkeringsbedragChanged(newValue?: number) {
    props.reportitem.uitkeringsbedrag = newValue ?? 0;
    await onChange();
}

async function onStatusChange(newStatus: StatusEnum): Promise<StatusEnum> {
    props.reportitem.status = newStatus;
    await onChange();
    const newItem           = await readAdminItem(props.reportitem);
    props.reportitem.status = newItem.status;
    return newItem.status!;
}

//===================================================================================================================
function dynColor() {
    return 'bg-' + StatusEnum_color(props.reportitem.status);
}

function labelClass(first: boolean) {
    const w = first ? 'w-44' : 'w-28';
    return `text-xs text-right ${w} mr-2`;
}

function renderMoment() {
    return renderTerseDate(props.reportitem.meetMoment!);
}
</script>

<template>
    <div class="flex flex-row items-start" :class="dynColor()">

        <!--DATE-->
        <div class="backdrop-brightness-90 font-extrabold text-center my-1 mx-1 w-32">
            {{ renderMoment() }}
        </div>

        <div class="flex flex-col w-full my-1 pr-4">

            <!--ROW -->
            <div class="flex flex-row items-center w-full my-1">

                <!-- gerapporteerde waarde-->
                <div class="flex flex-row items-center"
                     v-if="reportitem.showReportedValue()">
                    <div :class="labelClass(true)">
                        gerapporteerde waarde
                    </div>
                    <div class="font-bold">
                        <el-select
                            v-model="theValue"
                            placeholder="kies een optie"
                            style="min-width: 270px"
                            @keyup="keyupOnTheValue($event)"
                        >
                            <el-option
                                v-for="item in enum_values(MijlpaalEnum)"
                                :key="item"
                                :label="MijlpaalEnum_name(item)"
                                :value="item"
                            />
                        </el-select>
                    </div>
                </div>
                <div class="flex flex-row items-center"
                     v-else>
                    <div :class="labelClass(true)">
                    </div>
                    <div class="text-sm text-gray-400" style="min-width: 270px">
                        geen waarde, alleen prognose
                    </div>
                </div>

                <!-- prognose-->
                <div class="flex flex-row items-center"
                     v-if="reportitem.showPrognose()">
                    <div :class="labelClass(false)">
                        prognose
                    </div>
                    <div class="font-bold">
                        <div class="">
                            <el-select
                                v-model="thePrognose"
                                placeholder="kies een optie"
                                style="min-width: 270px"
                                @keyup="keyupOnThePrognose($event)"
                            >
                                <el-option
                                    v-for="item in enum_values(MijlpaalPrognoseEnum)"
                                    :key="item"
                                    :label="MijlpaalPrognoseEnum_name(item)"
                                    :value="item"
                                />
                            </el-select>
                        </div>
                    </div>
                </div>
            </div>

            <!--ROW: uitkering/vecozo-->
            <div class="flex flex-row items-center w-full my-1"
                 v-if="reportitem.showUitkeringsbedrag(true) || reportitem.showVecozoId(true)">

                <!--uitkeringsbedrag-->
                <div class="flex flex-row items-center"
                     v-if="reportitem.showUitkeringsbedrag(true)">
                    <div :class="labelClass(true)">
                        uitkeringsbedrag
                    </div>

                    <div class="font-bold">
                        <WaardeInvul
                            v-model:waarde="theUitkeringsbedrag"
                            :waarde-type="WaardeTypeEnum.bedrag"
                        />
                    </div>
                </div>

                <!--vecozo id-->
                <div class="flex flex-row items-center"
                     v-if="reportitem.showVecozoId(true)">
                    <div :class="labelClass(false)">
                        vecozo id
                    </div>
                    <div class="font-bold">
                        <input
                            class="px-2 py-1 border-1 w-full"
                            v-model="reportitem.vecozoId"
                            type="text"
                            style="min-width: 270px"
                            @input="onChange"
                        />
                    </div>
                </div>

            </div>

            <!--ROW: opmerking-->
            <div class="flex flex-row items-center w-full my-1">
                <div :class="labelClass(true)">
                    opmerking
                </div>

                <div class="flex-1"
                     title="opmerking">
                    <el-input
                        v-model="reportitem.opmerking"
                        @input="onChange()"
                        type="textarea"
                        :autosize="{ minRows: 1, maxRows: 5 }"
                    />
                </div>
            </div>

            <!--ROW: mitigatie-->
            <div class="flex flex-row items-center w-full my-1"
                 v-if="reportitem.showMitigatie()">
                <div :class="labelClass(true)">
                    mitigatie
                </div>

                <div class="flex-1"
                     title="mitigatie">
                    <el-input
                        v-model="reportitem.mitigatie"
                        @input="onChange()"
                        type="textarea"
                        :autosize="{ minRows: 1, maxRows: 5 }"
                    />
                </div>
            </div>

        </div>

        <div class="m-2">
            <StatusSelector :multi="false"
                            v-model:statusSet="statusTarget"
            />
        </div>
    </div>
</template>
