<script setup lang="ts">
import {onMounted, ref} from 'vue';
import {ElMessage} from 'element-plus';
import {type CabOrganization, CabUser, uuidOf} from 'common';
// noinspection ES6UnusedImports - usages in <template> are not properly detected!
import {USER_CHANGE_EMIT, USER_REMOVE_EMIT} from '@/base/cabConstants';
import {deleteUser, listOrganizations, listUsers, syncUsers, updateUser} from '@/base/callBackend';
import UserStrip from '@/views/admin/UserStrip.vue';
import PlusButton from '@/views/icons/PlusIcon.vue';
import SyncButton from '@/views/icons/SyncIcon.vue';
import GeneralFilter from '@/views/general/GeneralFilter.vue';

const users         = ref<CabUser[]>([]);
const organizations = ref<CabOrganization[]>([]);
const filterActive  = ref<boolean>(false);
const filteredUsers = ref<CabUser[]>([]);

onMounted(async () => {
    users.value         = await listUsers();
    organizations.value = await listOrganizations();
});

//=====================================================================================
async function onUserRemove(user: CabUser) {
    await deleteUser(user);
    users.value = users.value.filter((u) => uuidOf(u) !== uuidOf(user));
}

async function onUserAdd() {
    filterActive.value = false;
    const newUser      = new CabUser();
    await updateUser(newUser);
    users.value = [newUser, ...users.value];
}

async function onUserChange(user: CabUser) {
    await updateUser(user);
    users.value = users.value.map((u) =>
        uuidOf(u) === uuidOf(user) ? user : u,
    );
}

async function onUserSync() {
    filterActive.value = false;
    await syncUsers();
    ElMessage({
        message: 'Rapporteurs zijn gesynced met de inlog administratie',
        type   : 'success',
    });
}

//=====================================================================================
</script>

<template>
    <div class="flex flex-col flex-1 mx-28 my-5">
        <div class="flex flex-row flex-1 mb-1">
            <PlusButton @click="onUserAdd()"/>
            <SyncButton @click="onUserSync()"/>
            <div class="text-2xl flex-1 ml-2">
                Rapporteurs
            </div>
            <GeneralFilter v-model:active="filterActive"
                           v-model:cabList="users"
                           v-model:cabListFiltered="filteredUsers"
                           :id="'UserList'"/>
        </div>
        <UserStrip
            v-for="user in filteredUsers"
            :key="uuidOf(user)"
            :user="user"
            :allOrganizations="organizations"
            v-on:[USER_REMOVE_EMIT]="onUserRemove"
            v-on:[USER_CHANGE_EMIT]="onUserChange"
        />
    </div>
</template>
