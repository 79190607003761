import {ref} from 'vue';
import {ReportGroup} from '@/base/utils.js';

export const numVerleden = ref<number>();
export const numHeden    = ref<number>();
export const numToekomst = ref<number>();

export function newOverallNum(gr: ReportGroup, v: number) {
    switch (gr) {
        case ReportGroup.verleden:
            numVerleden.value = v;
            break;
        case ReportGroup.heden:
            numHeden.value = v;
            break;
        case ReportGroup.toekomst:
            numToekomst.value = v;
            break;
    }
}
