<script setup lang="ts">
import {onMounted, ref} from 'vue';
import {deleteTheme, listThemes, updateTheme} from '@/base/callBackend';
import {CabTheme, uuidOf} from 'common';
// noinspection ES6UnusedImports - usages in <template> are not properly detected!
import {THEME_CHANGE_EMIT, THEME_REMOVE_EMIT} from '@/base/cabConstants';
import ThemeStrip from '@/views/admin/ThemeStrip.vue';
import PlusButton from '@/views/icons/PlusIcon.vue';
import GeneralFilter from '@/views/general/GeneralFilter.vue';

defineEmits([THEME_CHANGE_EMIT, THEME_REMOVE_EMIT]);

const themes         = ref<CabTheme[]>([]);
const filteredThemes = ref<CabTheme[]>([]);
const filterActive   = ref<boolean>(false);

onMounted(async () => {
    themes.value = await listThemes();
});

//=====================================================================================
async function onThemeRemove(theme: CabTheme) {
    await deleteTheme(theme);
    themes.value = themes.value.filter((o) => uuidOf(o) !== uuidOf(theme));
}

async function onThemeAdd() {
    filterActive.value = false;
    const newTheme     = new CabTheme();
    await updateTheme(newTheme);
    themes.value = [newTheme, ...themes.value];
}

async function onThemeChange(theme: CabTheme) {
    const uuid = uuidOf(theme);
    await updateTheme(theme);
    themes.value = themes.value.map((o) =>
        uuidOf(o) === uuid ? theme : o,
    );
}

</script>

<template>
    <div class="flex flex-col flex-1 mx-28 my-5">
        <div class="flex flex-row flex-1 mb-1">
            <PlusButton @click="onThemeAdd()"/>
            <div class="text-2xl flex-1 ml-2">
                Thema's
            </div>

            <GeneralFilter v-model:active="filterActive"
                           v-model:cab-list="themes"
                           v-model:cab-list-filtered="filteredThemes"
                           :id="'ThemeList'"
            />
        </div>
        <ThemeStrip
            v-for="theme in filteredThemes"
            :key="uuidOf(theme)"
            :theme="theme"
            v-on:[THEME_REMOVE_EMIT]="onThemeRemove"
            v-on:[THEME_CHANGE_EMIT]="onThemeChange"
        />
    </div>
</template>
