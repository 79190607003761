<script setup lang="ts">
import KpiIcon from '@/views/icons/KpiIcon.vue';
import {ElBadge} from 'element-plus';
import {computed} from 'vue';

const props = defineProps({
    to   : {
        type    : String,
        required: true,
    },
    name : String,
    icon : String,
    badge: Number,
});

const badgeString = computed<string>(() => props.badge ? props.badge.toString() : '');
const badgeOffset = computed<[number, number]>(() => [18 - 7.5 * badgeString.value.length, -8]);

</script>

<template>
    <router-link
        :to="to"
        class="px-3 py-2 text-gray-700 border-gray-400 hover:border-gray-500 border-b-4 bg-stone-200"
        exact-active-class="!border-gray-700"
    >
        <el-badge
            :value="badgeString"
            :color="badgeString==='0' ? 'green' : 'red'"
            class="item"
            :offset="badgeOffset"
        >
            <div class="flex flex-row items-center">
                <KpiIcon :name="icon" v-if="icon"/>
                {{ name }}
            </div>
        </el-badge>
    </router-link>
</template>
