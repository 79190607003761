import {computed, ComputedRef, ref} from 'vue';
import {getMeInfo, serverTimeSyncsWithMine} from '@/base/me.js';
import {BackendError} from '@/base/callBackend.js';
import {renderFullDateLocal} from 'common';
import {Ref} from '@vue/runtime-dom';
import {closedForReporters} from '@/base/TMP_globalState.js';

export enum LoadingState {
    LOADING          = 'loading',
    DONE             = 'done',
    CLOSED           = 'closed',
    TIME_ERROR       = 'time_error',
    GENERAL_ERROR    = 'general_error',
    PERMISSION_ERROR = 'permission_error'
}

export const loadingState: Ref<LoadingState>           = ref<LoadingState>(LoadingState.LOADING);
export const timeErrorMessage: Ref<string | undefined> = ref<string>();
export const done: ComputedRef<boolean>                = computed<boolean>(() => loadingState.value === LoadingState.DONE);

export async function reload(callback: () => Promise<void>) {
    try {
        closedForReporters.value = false;
        loadingState.value       = LoadingState.LOADING;
        if (!await serverTimeSyncsWithMine()) {
            timeErrorMessage.value = await timeErrorInfo();
            loadingState.value     = LoadingState.TIME_ERROR;
        } else {
            await callback();
            loadingState.value = LoadingState.DONE;
        }
    } catch (e) {
        loadingState.value = LoadingState.GENERAL_ERROR;
        if (e instanceof BackendError) {
            switch (e.details.reason) {
                case 'CLOSED':
                    closedForReporters.value = true;
                    loadingState.value       = LoadingState.CLOSED;
                    break;
                case 'PERMISSION':
                    loadingState.value = LoadingState.PERMISSION_ERROR;
                    break;
            }
        }
    }
}

async function timeErrorInfo(): Promise<string> {
    const me = await getMeInfo();
    if (!me) {
        return 'geen techinfo beschikbaar';
    }
    const serverTime = renderFullDateLocal(new Date(me.serverTimestamp));
    const localTime  = renderFullDateLocal(new Date(me.serverTimestamp + me.serverBehindLocalTime));
    const diff       = Math.abs(me.serverBehindLocalTime);
    return `device: ${localTime}<br>server: ${serverTime}<br>diff: ${diff} ms (incl network delay)`;
}
