import {createRouter, createWebHistory} from 'vue-router';
import LoginDialog from '@/authentication/LoginDialog.vue';
import ReportLanding from '@/views/header/ReportLanding.vue';
import AdminLanding from '@/views/header/AdminLanding.vue';
import KPIList from '@/views/admin/KPIList.vue';
import UserList from '@/views/admin/UserList.vue';
import OrganizationList from '@/views/admin/OrganizationList.vue';
import ThemeList from '@/views/admin/ThemeList.vue';
import PasswordResetDialog from '@/authentication/PasswordResetDialog.vue';
import TabBeheerAdmin from '@/views/admin/TabBeheerAdmin.vue';
import AdminReportList from '@/views/admin/AdminReportList.vue';
import {meHasPermission} from '@/base/me.js';
import TabVerleden from '@/views/report/TabVerleden.vue';
import TabHeden from '@/views/report/TabHeden.vue';
import TabToekomst from '@/views/report/TabToekomst.vue';
import TabBeheerReporter from '@/views/report/TabBeheerReporter.vue';


export enum KpiSelectEnum {
    onlyMilestones,
    allOthers
}

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes : [
        {
            path     : '/',
            name     : 'login',
            component: LoginDialog,
        },
        {
            path     : '/auth/reset-password',
            name     : 'password reset',
            component: PasswordResetDialog,
        },
        {
            path     : '/admin',
            name     : 'admin',
            component: AdminLanding,
            meta     : {requires: 'admin'},
            redirect : () => getStoredTab('admin', '/admin/thema'),
            children : [
                {
                    path     : 'thema',
                    component: ThemeList,
                    meta     : {requires: 'admin'},
                },
                {
                    path     : 'organization',
                    component: OrganizationList,
                    meta     : {requires: 'admin'},
                },
                {
                    path     : 'user',
                    component: UserList,
                    meta     : {requires: 'admin'},
                },
                {
                    path     : 'milestone',
                    component: KPIList,
                    props    : {kpiSelect: KpiSelectEnum.onlyMilestones},
                    meta     : {requires: 'admin'},
                },
                {
                    path     : 'kpi',
                    component: KPIList,
                    props    : {kpiSelect: KpiSelectEnum.allOthers},
                    meta     : {requires: 'admin'},
                },
                {
                    path     : 'status',
                    component: AdminReportList,
                    meta     : {requires: 'admin'},
                },
                {
                    path     : 'admin',
                    component: TabBeheerAdmin,
                    meta     : {requires: 'admin'},
                },
            ],
        },
        {
            path     : '/report',
            name     : 'report',
            component: ReportLanding,
            meta     : {requires: 'report'},
            redirect : () => getStoredTab('report', '/report/heden'),
            children : [
                {
                    path     : 'verleden',
                    component: TabVerleden,
                    meta     : {requires: 'report'},
                },
                {
                    path     : 'heden',
                    component: TabHeden,
                    meta     : {requires: 'report'},
                },
                {
                    path     : 'toekomst',
                    component: TabToekomst,
                    meta     : {requires: 'report'},
                },
                {
                    path     : 'beheer',
                    component: TabBeheerReporter,
                    meta     : {requires: 'report'},
                },
            ],
        },
        {
            path    : '/:pathMatch(.*)*',
            redirect: '/',
        },
    ],
});

function getStoredTab(perm: string, def: string): string {
    return localStorage.getItem(`activeTab:${perm}`) ?? def;
}

router.beforeEach(async function (to: any, _from: any, next: any) {
    const perm = to.meta.requires;
    if (!perm) {
        next();
    } else if (!await meHasPermission(perm)) {
        next('/');
    } else {
        if (to.path !== '/') {
            localStorage.setItem(`activeTab:${perm}`, to.path);
        }
        next();
    }
});

export default router;
