import {ElMessage, ElMessageBox} from 'element-plus';
import {riCsvExport} from '@/base/callBackend.js';
import {generateDateId} from 'common';

export enum ReportGroup {
    verleden = 'verleden',
    heden    = 'heden',
    toekomst = 'toekomst'
}

export async function askForTag(title: string) {
    try {
        const {value} = await ElMessageBox.prompt(`Geef een tag:`, title, {
            confirmButtonText: 'OK',
            cancelButtonText : 'Cancel',
            inputPattern     : /^[-A-Za-z0-9_]*$/,
            inputErrorMessage: `Tags bestaan slechts uit letters, cijfers, '-' en '_'`,
            inputPlaceholder : 'download',
        });
        return value ? value : 'download';
    } catch (error) {
        return undefined;
    }
}

export async function downloadReportingItemsCsv(filterForCurrentUser: boolean = true) {
    const tag = filterForCurrentUser ? 'reporter-dump' : await askForTag('CSV Download');
    if (tag) {
        const response = await riCsvExport(filterForCurrentUser);
        if (!response.ok) {
            const data = await new Response(response.body).json();
            ElMessage({
                message: `Failed: ${data.message}`,
                type   : 'error',
            });
        } else {
            await downloadFile(response, `zzc-${generateDateId()}-${tag}.csv`);
        }
    }
}

export async function downloadFile(response: Response, filename: string) {
    if (!response.ok) {
        ElMessage({
            message: `Failed: ${response.statusText}`,
            type   : 'error',
        });
    } else {
        const blob: Blob  = await response.blob();
        const url: string = URL.createObjectURL(blob);
        const link        = document.createElement('a');
        link.href         = url;
        link.download     = filename;
        link.click();
        URL.revokeObjectURL(url);

        const l: string | null = response.headers.get('content-length');
        const msg: string      = l
                                 ? `Downloaded ${Math.floor(+l / 1024)} kb`
                                 : 'Download done';
        ElMessage({
            message: `${msg}`,
            type   : 'success',
        });
    }
}

export function localStorageGetJson(name: string): any {
    const item = localStorage.getItem(name);
    if (!item) {
        return undefined;
    }
    try {
        return JSON.parse(item);
    } catch (e) {
        return undefined;
    }
}

export function localStorageSetJson(name: string, o: any) {
    localStorage.setItem(name, JSON.stringify(o));
}

export type SelectGroupInfo = {
    name: string,
    id: string | number,
}

export async function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
