<script setup lang="ts">
import {onMounted, ref} from 'vue';
import {deleteOrganization, listOrganizations, listThemes, updateOrganization} from '@/base/callBackend';
import {CabOrganization, CabTheme, uuidOf} from 'common';
// noinspection ES6UnusedImports - usages in <template> are not properly detected!
import {ORGANIZATION_CHANGE_EMIT, ORGANIZATION_REMOVE_EMIT} from '@/base/cabConstants';
import OrganizationStrip from '@/views/admin/OrganizationStrip.vue';
import PlusButton from '@/views/icons/PlusIcon.vue';
import GeneralFilter from '@/views/general/GeneralFilter.vue';

const organizations         = ref<CabOrganization[]>([]);
const themes                = ref<CabTheme[]>([]);
const filterActive          = ref<boolean>(false);
const filteredOrganizations = ref<CabOrganization[]>([]);

onMounted(async () => {
    organizations.value = await listOrganizations();
    themes.value        = await listThemes();
});

//=====================================================================================
async function onOrganizationRemove(organization: CabOrganization) {
    await deleteOrganization(organization);
    organizations.value = organizations.value.filter((o) => uuidOf(o) !== uuidOf(organization));
}

async function onOrganizationAdd() {
    filterActive.value    = false;
    const newOrganization = new CabOrganization();
    await updateOrganization(newOrganization);
    organizations.value = [newOrganization, ...organizations.value];
}

async function onOrganizationChange(organization: CabOrganization) {
    const uuid = uuidOf(organization);
    await updateOrganization(organization);
    organizations.value = organizations.value.map((o) =>
        uuidOf(o) === uuid ? organization : o,
    );
}

//=====================================================================================
</script>

<template>
    <div class="flex flex-col flex-1 mx-28 my-5">
        <div class="flex flex-row flex-1 mb-1">
            <PlusButton @click="onOrganizationAdd()"/>
            <div class="text-2xl flex-1 ml-2">
                Instellingen
            </div>
            <GeneralFilter v-model:active="filterActive"
                           v-model:cabList="organizations"
                           v-model:cabListFiltered="filteredOrganizations"
                           :id="'OrganizationList'"/>
        </div>
        <OrganizationStrip
            v-for="organization in filteredOrganizations"
            :key="uuidOf(organization)"
            :organization="organization"
            :allThemes="themes"
            v-on:[ORGANIZATION_REMOVE_EMIT]="onOrganizationRemove"
            v-on:[ORGANIZATION_CHANGE_EMIT]="onOrganizationChange"
        />
    </div>
</template>
